const Logo = () => {
  return (
    <svg width={124} height={40} fill="none">
      <path
        d="M29.8 3H4.2C1.88 3 0 4.883 0 7.207V32.58a4.203 4.203 0 004.2 4.207h25.6c2.32 0 4.2-1.883 4.2-4.207V7.207A4.203 4.203 0 0029.8 3z"
        fill="#1B262C"
      />
      <ellipse cx={22.131} cy={27.422} rx={5.09} ry={5.294} fill="#fff" />
      <path
        className="logo-bg"
        d="M58.547 28.172v-5.297h1.906c2.719 0 3.734 1 3.734 2.594 0 1.562-1.14 2.703-3.734 2.703h-1.906zm0-9.047v-4.828h1.734c2.344 0 3.235.906 3.235 2.344 0 1.453-1.016 2.484-3.22 2.484h-1.75zm-5.313 10.453c0 1.516.891 2.422 2.438 2.422h4.562c6.641 0 9.453-2.828 9.453-6.688 0-2.578-1.671-4.296-4.14-4.703v-.062c2.14-.703 3.406-2.375 3.406-4.703 0-3-2.375-5.375-8.437-5.375h-4.844c-1.547 0-2.438.906-2.438 2.422v16.687zm19.835-5.312c0 4.859 3.235 8.062 8.36 8.062 5.484 0 8.718-3.234 8.718-8.594 0-4.859-3.218-8.078-8.343-8.078-5.485 0-8.735 3.25-8.735 8.61zm5.407-.297c0-2.938 1.375-4.313 3.109-4.313 1.75 0 3.172 1.422 3.172 4.375 0 2.938-1.36 4.313-3.11 4.313-1.765 0-3.171-1.407-3.171-4.375zm15.757 5.828c0 1.469.859 2.312 2.656 2.312 1.812 0 2.656-.843 2.656-2.312v-7.14c.313-.579 1.125-1.782 2.094-1.782.515 0 .797.281 1.265.281.985 0 1.782-1.172 1.782-3.156 0-1.625-.75-2.344-2-2.344-1.672 0-2.954 1.172-3.688 2.797h-.047l-.203-.922c-.266-1.015-.906-1.703-2.297-1.703-1.437 0-2.218.766-2.218 2.203v11.766zm25.381-4.61c1.516 0 2.407-.875 2.407-2.656 0-4.25-2.641-6.875-6.922-6.875-4.985 0-8.36 3.36-8.36 8.875 0 4.766 3.063 7.797 8.329 7.797 3.062 0 4.906-.86 5.593-1.547.438-.437.625-.875.625-1.515 0-.86-.562-1.61-1.531-1.61-.844 0-1.75.625-3.719.625-2.328 0-3.703-1.078-4.031-3.062v-.032h7.609zm-7.609-3.03c.375-2.095 1.406-2.845 2.641-2.845 1.453 0 2.234.875 2.468 2.844h-5.109z"
      />
    </svg>
  );
};

export default Logo;

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';
import { Document, Page, pdfjs } from 'react-pdf';

import { errorIcon } from '@/assets/images';
import { Pagination, getCls } from '@/components/global';
import { ImageService } from '@/services';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const DEFAULT_CLS = 'h-auto w-auto';
const DEFAULT_CLS_CONTAINER = 'h-auto w-auto flex items-center justify-center';

enum PdfStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface PdfProps {
  /**
   * @default ""
   */
  url: string;

  /**
   * @default ""
   */
  cls?: string;

  /**
   * @default ""
   */
  clsContainer?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultClsContainer?: boolean;
}

/**
 *
 * @param  {string} url pdf url
 * @param {string} cls pdf className for custom styling
 * @example <pdf url="pdf.png" cls="pdf-style" alt="avatar" />
 */
const Pdf: FC<PdfProps> = ({
  url,
  cls = '',
  clsContainer = '',
  applyDefaultCls = true,
  applyDefaultClsContainer = true,
}) => {
  const [pdfStatus, setPdfStatus] = useState(PdfStatus.INITIAL);

  cls = getCls(applyDefaultCls, DEFAULT_CLS, cls);
  clsContainer = getCls(
    applyDefaultClsContainer,
    DEFAULT_CLS_CONTAINER,
    clsContainer
  );

  const [pdfReader, setPdfReader] = useState<File>();

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const getPdfRessource = () => {
    setPdfStatus(PdfStatus.IN_PROGRESS);
    ImageService.getImage(url)
      .then((res) => {
        let filename = 'your-pdf-file.pdf';
        const pdfFile = new File([res.data], filename, {
          type: 'application/pdf',
        });
        setPdfReader(pdfFile);
        setPdfStatus(PdfStatus.SUCCESS);
      })
      .catch(() => {
        setPdfStatus(PdfStatus.ERROR);
      });
  };
  const renderSwitch = () => {
    switch (pdfStatus) {
      case 'ERROR':
        return <PdfError />;

      case 'IN_PROGRESS':
        return <RotatingLines visible={true} strokeColor="grey" width="30" />;

      case 'SUCCESS':
        return (
          <div className={clsContainer}>
            <Document
              file={pdfReader}
              className={cls}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                height={560}
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
            {numPages !== null && (
              <Pagination
                currentPage={pageNumber}
                totalPages={numPages}
                onPageChange={setPageNumber}
                isPreloadState={false}
              />
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    getPdfRessource();
  }, [url]);

  return <div className={clsContainer}>{renderSwitch()}</div>;
};

const PdfError = () => {
  return (
    <div className="flex flex-col items-center w-auto h-auto gap-2">
      <img className="max-w-8 max-h-8" src={errorIcon} alt="error" />
    </div>
  );
};

export default Pdf;

import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import {
  AccountBalanceOutlined,
  ArrowUpward,
  ContactlessOutlined,
  InsertChartOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo, Upload, bore, hide } from '@/assets/images';
import { LoadingSpinner } from '@/components/global';
import { useAppDispatch, useAppSelector, useAppTranslation } from '@/hooks';
import { OperationsService } from '@/services';
import {
  setKpiValidationReceipt,
  setKpiValidationTransaction,
} from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';
import { cls } from '@/utils/funcs';

import { LockedBackdrop } from './LockedBackdrop';

interface SidebarProps {
  locked?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ locked = false }) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const validatedReceiptNumber = useAppSelector(
    (store) => store.global.validatedReceiptNumber
  );
  const validatedTransactionNumber = useAppSelector(
    (store) => store.global.validatedTransactionNumber
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedItem, setSelectedItem] = useState('Performances');
  const navigate = useNavigate();
  const location = useLocation();
  const dispayKpiValidation = (kpi: any) => {
    return (kpi ?? 0) > 0 && kpi;
  };

  const navItems = useMemo(
    () => [
      {
        type: 'item',
        name: 'Performances',
        icon: <InsertChartOutlined />,
        href: '/dashboard/performances',
      },
      {
        type: 'item',
        name: 'Receipts',
        icon: <ReceiptLongOutlined />,
        notificationCount: dispayKpiValidation(validatedReceiptNumber),
        href: '/dashboard/receipts',
      },
      {
        type: 'item',
        name: 'Transactions',
        icon: <ContactlessOutlined />,
        notificationCount: dispayKpiValidation(validatedTransactionNumber),
        href: '/dashboard/transactions',
      },
      {
        type: 'item',
        name: 'Statements',
        icon: <AccountBalanceOutlined />,
        href: '/dashboard/statements',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );

  const toggleSidebarExpansion = () =>
    setIsSidebarExpanded((isSidebarExpanded) => !isSidebarExpanded);

  const handleItemClick = (href: string) => {
    navigate(href);
  };

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setSelectedItem('/settings');
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    OperationsService.getKpiValidation().then((res) => {
      dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
      dispatch(
        setKpiValidationTransaction(res.data?.validatedTransactionNumber)
      );
    });
  }, [dispatch]);

  const handleFileDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        let formData = new FormData();
        formData.append('file', file);
        OperationsService.createReceipt(formData)
          .then(() => {
            i == acceptedFiles.length - 1 && setIsLoading(false);
          })
          .catch(() => {
            i == acceptedFiles.length - 1 && setIsLoading(false);
          });
      }
    }
  };

  return (
    <div className="relative">
      {locked && <LockedBackdrop />}
      <div
        className={cls(
          'hidden min-[430px]:flex min-[430px]:flex-col min-[430px]:relative',
          isSidebarExpanded ? 'min-w-[250px]' : 'min-w-[65px]'
        )}
      >
        <div className="h-[100%] fixed overflow-y-auto scroll-bar-style overflow-x-hidden bg-first pt-[80px]">
          <div
            className={cls(
              'h-full flex duration-500 flex-col transition-width',
              isSidebarExpanded ? 'w-[250px]' : 'w-[65px]'
            )}
          >
            <div className="grow items-center justify-center flex bg-red">
              <div className="ml-auto mr-auto w-[80%] h-full pt-[3vh] pb-[3vh] bg flex flex-col gap-2">
                {navItems.map(
                  ({ type, name, notificationCount, icon, href }, idx) =>
                    type === 'item' ? (
                      <div
                        key={href}
                        className={cls(
                          href === selectedItem && 'bg-blue-low-opacity',
                          isSidebarExpanded ? 'gap-1 pr-2' : 'gap-4 pl-[3px]',
                          'flex rounded-lg items-center py-3.5 cursor-pointer hover:bg-[#eef1fc] dark:hover:bg-[#1b1d2c] transition-colors duration-200'
                        )}
                        onClick={() => href && handleItemClick(href)}
                      >
                        <div
                          className={cls(
                            'pr-2 pl-3',
                            href === selectedItem
                              ? 'stroke-[#1B262C]'
                              : 'stroke-[#8D98AF]'
                          )}
                        >
                          {icon}
                        </div>
                        <div className="flex justify-between w-full gap-2 items-center">
                          <p
                            className={cls(
                              href === selectedItem
                                ? 'color-blue'
                                : 'color-black opacity-60',
                              'font-semibold w-full'
                            )}
                          >
                            {t(name)}
                          </p>
                          {notificationCount && (
                            <p className="text-xs bg-[#FDF9EC] text-[#7A5802] font-medium px-2 py-1 rounded-full">
                              {notificationCount}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <hr key={idx} className="my-2" />
                    )
                )}
              </div>
            </div>

            <div className="h-[400px] transition-all duration-300	w-[90%] ml-auto mr-auto justify-end items-center flex flex-col cursor-pointer">
              {isSidebarExpanded ? (
                <Dropzone onDrop={handleFileDrop} disabled={isLoading}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="w-full relative">
                      <div
                        className="border-dashed border-2 border-gray-300 rounded-lg h-[200px] items-center hover:bg-gray-50 duration-300"
                        {...getRootProps()}
                      >
                        <div className="h-full flex justify-center items-center">
                          <div>
                            <input {...getInputProps()} />
                            <div className="flex justify-center items-center flex-col w-full ">
                              <Upload />
                              <p className="color-grey font-bold">
                                Drag & drop
                              </p>
                            </div>
                            <p className="color-black text-xs mt-2 text-center w-[80%] ml-auto mr-auto">
                              PNG, JPG , PDF ( max. 900 MB )
                            </p>
                          </div>
                          {isLoading && (
                            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 opacity-75 z-10">
                              <LoadingSpinner strokeColor="blue" />
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <PrimaryButton /> */}
                    </section>
                  )}
                </Dropzone>
              ) : (
                <div className="w-[40px] h-[40px] rounded-md cursor-pointer color-button-text flex items-center justify-center font-size-sm bg-[#1B262C]">
                  <ArrowUpward />
                </div>
              )}

              <div className="flex justify-center pt-6 pb-6">
                <button onClick={toggleSidebarExpansion}>
                  <img
                    src={hide}
                    alt="Hide"
                    style={{ rotate: isSidebarExpanded ? undefined : '180deg' }}
                  />
                </button>
              </div>
              <div className="flex justify-center">
                {isSidebarExpanded ? (
                  <Logo />
                ) : (
                  <img alt="Bore-logo" src={bore} />
                )}
              </div>
              <h4 className="text-center pt-4 text-black pb-4">
                V {APP_VERSION}
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* 430px (mobile) Navbar */}
      <div className="min-[430px]:hidden max-[430px]:block bg-first fixed bottom-0 w-full">
        <div className="flex  justify-between p-2">
          {navItems.map(({ name, icon, notificationCount }, idx) => (
            <div
              key={idx}
              className={`${
                name === selectedItem && 'bg-blue-low-opacity'
              } flex rounded-lg	 items-center pb-3 pr-4 pt-3 gap-4`}
            >
              <div
                className={`pr-2 pl-3 ${
                  name === selectedItem
                    ? 'stroke-[#1B262C]'
                    : 'stroke-[#989A9E]'
                }`}
              >
                {icon}{' '}
              </div>
              <p
                className={`${
                  name === selectedItem
                    ? 'color-blue'
                    : 'color-black opacity-60'
                }   font-semibold w-full`}
              >
                {t(name as string)}
              </p>
              <h3 className="bg-blue color-button-text  pl-2 pr-2 rounded-full	">
                {notificationCount}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import { useMutation } from '@tanstack/react-query';

import type { UpdatePasswordToSubmitType } from '@/lib/forms';
import { AuthService } from '@/services';

export const useUpdatePassword = () =>
  useMutation({
    mutationFn: (data: UpdatePasswordToSubmitType) => {
      return AuthService.updatePassword(data);
    },
  });

import type { FC } from 'react';

import { Outlet } from 'react-router-dom';

interface JarProps {}

const Jar: FC<JarProps> = () => {
  return <Outlet />;
};

export default Jar;

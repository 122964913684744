enum ActionTypesEnum {
  SET_IS_PRELOAD_STATE = 'SET_IS_PRELOAD_STATE',
  SET_CONNECTED_USER = 'SET_CONNECTED_USER',

  SET_COMPANY_INFO = 'SET_COMPANY_INFO',
  SET_IS_LOADING_COMPANY_INFO = 'SET_IS_LOADING_COMPANY_INFO',
  SET_COMPANY_SUBSCRIPTION = 'SET_COMPANY_SUBSCRIPTION',

  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',

  SET_KPI_VALIDATION_RECEIPT = 'SET_KPI_VALIDATION_RECEIPT',
  SET_KPI_VALIDATION_TRANSACTION = 'SET_KPI_VALIDATION_TRANSACTION',
}

export default ActionTypesEnum;

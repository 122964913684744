import React from 'react';

import Menu from '@mui/material/Menu';

import Button from '../../global/Button/Button';

interface ExpiredPlanButtonProps {
  buttonLabel: string;
  heading: string;
  description: string;
}

function ExpiredPlanButton({
  buttonLabel,
  heading,
  description,
}: ExpiredPlanButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="lock-button"
        aria-haspopup="listbox"
        aria-controls="lock-menu"
        aria-label="when device is locked"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickListItem}
        labelCls="font-bold"
        label={buttonLabel}
        cls="bg-[#DA8164] bg-opacity-30 text-[#DA8164]"
      />

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <div className="p-4">
          <p className="text-[#23C38E] font-semibold ">{heading}</p>
          <p className="opacity-80 pt-2 pb-2 color-black max-w-[300px]">
            {description}
          </p>
        </div>
      </Menu>
    </div>
  );
}

export default ExpiredPlanButton;

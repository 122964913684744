/**
 * Join CSS classes
 * @param {any[]} ...classNames list of classes passed as arguments
 * @return {string} a string that contains the classes separated by space
 * @example cls('cls1', false && 'cls2', true && 'cls3') => 'cls1 cls3'
 */
export const cls = (...classNames: any[]): string =>
  !classNames || !Array.isArray(classNames)
    ? ''
    : classNames.filter((el) => !!el && typeof el == 'string').join(' ');

/**
 * Get an array of duplicate values
 * @param {any} value the value to duplicate
 * @param {number} length the length of the array
 * @return {any[]} an array that contains the duplicate values
 * @example getArrayOfDuplicateValues('Test', 3) => ['Test', 'Test', 'Test']
 */
export const getArrayOfDuplicateValues = (value: any, length: number): any[] =>
  Array.from({ length }, () => value);

/**
 *
 * @param {string} rib the rib to convert to **1234
 * @returns {string} converted rib
 */
export const convertRib = (rib: string) => `***${rib.substr(rib.length - 4)}`;

/**
 *
 * @param {number} amount the amount to formate
 * @param {string} Currency the amount currency
 * @example formatBalance(14050.411, "TND")
 * @returns 14.050,411 TND
 */
export const formatBalance = (amount?: number | string, currency?: string) => {
  const formattedAmount = (amount as unknown as number) * 1;
  return (
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency ?? 'TND',
      //? In order to display all digits the line below should stay commented
      // maximumSignificantDigits: 3,
    }).format(formattedAmount) || 'Amount not valid'
  );
};

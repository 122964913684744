import * as yup from 'yup';

import { validatePassword } from './password-validator';

export type SignUpFormType = {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmedPassword: string;
  agreeToTerms: boolean;
};

export type SignInFormType = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type UpdatePasswordFormType = {
  password: string;
  confirmedPassword: string;
};

export type ResetPasswordFormType = {
  email: string;
};

export const signUpFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  surname: yup.string().required(),
  password: yup.string().test(validatePassword),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match'),
  agreeToTerms: yup.bool().oneOf([true]),
});

export const signInFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string(),
  rememberMe: yup.bool(),
});

export const resetPasswordFormSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const updatePasswordFormSchema = yup.object().shape({
  password: yup.string().test(validatePassword),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match'),
});

export type UpdatePasswordToSubmitType = UpdatePasswordFormType & {
  recaptchaToken: string | null;
  resetToken: String;
};

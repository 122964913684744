import * as yup from 'yup';

export type CompanyInfosFormType = {
  name: string;
  legalStatus: string;
  address: string;
  taxId: string;
  logo: File | string;
  companyName: string;
  registerNum?: string;
};

export type CompanyInfosSubmittedType = CompanyInfosFormType & {
  nreData: any;
};

export type CompanyFormType = {
  name?: string | null;
  legalStatus?: string | null;
  taxId?: string | null;
  address?: string | null;
};

export const CompanyInfosFormSchema = yup.object().shape({
  address: yup.string().required(),
  legalStatus: yup.string().required(),
  logo: yup.mixed(),
  name: yup.string().required(),
  taxId: yup.string().required(),
});

export const companyFormSchema = yup.object().shape({
  address: yup.string().required(),
  legalStatus: yup.string().required(),
  name: yup.string().required(),
  taxId: yup.string().required(),
});

export type InviteUserFormType = {
  email?: string | null;
  role?: string | null;
  companyId?: string | null;
};

export const InviteUserFormSchema = yup.object().shape({
  email: yup.string().required(),
  role: yup.string().required(),
});

import axios from 'axios';

import {
  defaultAPIRequestMiddleware,
  defaultAPIResponseMiddleware,
} from '@/lib/interceptors';

const BASE_URL = '';

const imageAPI = axios.create({
  baseURL: BASE_URL,
  responseType: 'blob',
});

imageAPI.interceptors.request.use(defaultAPIRequestMiddleware, (error) =>
  Promise.reject(error)
);

imageAPI.interceptors.response.use(
  (res) => res,
  (error) => defaultAPIResponseMiddleware(error, imageAPI)
);

export default imageAPI;

export const validatePassword = (val: string | undefined, ctx: any) => {
  const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
  const LOWERCASE_REGEX = new RegExp(/.*[a-z]/);
  const DIGIT_REGEX = new RegExp(/.*\d/);
  const SPECIAL_CHARS_REGEX = new RegExp(
    /.*[²&~«“#\\'"‘{|_`^@()\[\]°=+}<>,?;.:/!§¡¨$£¤*µ%-]/
  );

  const VALID_PASSWORD_REGEX = new RegExp(
    `^(?=${[
      UPPERCASE_REGEX.source,
      LOWERCASE_REGEX.source,
      DIGIT_REGEX.source,
      SPECIAL_CHARS_REGEX.source,
    ].join(')(?=')}).*$`
  );

  if (
    !!val &&
    val.toString().length >= 8 &&
    VALID_PASSWORD_REGEX.test(val as string)
  ) {
    return true;
  }
  return ctx.createError();
};

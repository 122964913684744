import type { ComponentProps, FC, ReactNode } from 'react';

import type { ButtonSensitivity, ButtonSize, IconPosition } from '..';
import { LoadingSpinner, getCls } from '..';

const DEFAULT_CLS_SMALL =
  'text-[12px] h-[24px] rounded-[4px] px-4 flex items-center justify-center';

const DEFAULT_CLS_MEDIUM =
  'text-[14px] h-[28px] rounded-[4px] px-4 flex items-center justify-center';

const DEFAULT_CLS_BIG =
  'text-[16px] h-[40px] rounded-[4px] px-4 flex items-center justify-center';

const DEFAULT_LABEL_CLS_PRIMARY = 'font-bold px-1 text-white';

const DEFAULT_LABEL_CLS_SECONDARY = 'font-bold px-1 text-[#30313D]';

const DEFAULT_LABEL_CLS_DESTRUCTIVE = 'font-bold px-1 text-white';

interface ButtonProps extends ComponentProps<'button'> {
  /**
   * @default ''
   */
  cls?: string;

  /**
   * @default ''
   */
  label?: string | ReactNode /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * @default null
   */
  loadingSpinnerProps?: ComponentProps<typeof LoadingSpinner>;

  /**
   * @default 'medium''
   */
  size?: ButtonSize;

  /**
   * @default 'primary''
   */
  sensitivity?: ButtonSensitivity;

  // children?: string | ReactNode;
}

/**
 * Global Button Component
 * @param {string} label label text
 * @param {string} cls button class
 * @param {string} labelCls label class
 * @param {boolean} applyDefaultCls whether to apply the default button class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {ReactNode} icon icon component
 * @param {IconPosition} iconPosition icon position
 * @param {boolean} isLoading whether to show the loading spinner
 * @param {any[]} ...props list of props to pass to the native button element
 * @example <Button label="Submit" icon={<CheckCircleIcon />} cls="hover:bg-gray-200 transition duration-150 ease-out hover:ease-in" isLoading={isLoading} loadingSpinnerProps={{ strokeColor: 'lightgrey' }} />
 */
const Button: FC<ButtonProps> = ({
  cls = '',
  label = '',
  labelCls = '',
  applyDefaultCls = true,
  applyDefaultLabelCls = true,
  icon = null,
  iconPosition = 'left',
  isLoading = false,
  loadingSpinnerProps = null,
  size = 'medium',
  sensitivity = 'Primary',
  ...props
}) => {
  const getButtonSizeCLS = (size: ButtonSize) => {
    const sizeCLS = {
      big: DEFAULT_CLS_BIG,
      small: DEFAULT_CLS_SMALL,
      medium: DEFAULT_CLS_MEDIUM,
    };

    return sizeCLS[size];
  };

  const getButtonLabelCLS = (sensitivity: ButtonSensitivity) => {
    const labelCLS = {
      Primary: DEFAULT_LABEL_CLS_PRIMARY,
      Secondary: DEFAULT_LABEL_CLS_SECONDARY,
      Destructive: DEFAULT_LABEL_CLS_DESTRUCTIVE,
    };

    return labelCLS[sensitivity];
  };

  const getButtonSensitivityCLS = (sensitivity: ButtonSensitivity) => {
    const buttonCLS = {
      Primary: 'bg-[#1B262C]',
      Secondary: 'border border-[#D5DBE1] bg-white',
      Destructive: 'bg-[#DF1B41]',
    };

    return buttonCLS[sensitivity];
  };

  cls = getCls(applyDefaultCls, getButtonSizeCLS(size), cls);

  labelCls = getCls(
    applyDefaultLabelCls,
    getButtonLabelCLS(sensitivity),
    labelCls
  );

  return (
    <button
      {...props}
      className={`${cls} ${getButtonSensitivityCLS(sensitivity)}`}
    >
      {isLoading ? (
        <LoadingSpinner {...loadingSpinnerProps} />
      ) : (
        <>
          {iconPosition === 'left' && icon}
          {typeof label === 'string' ? (
            <span className={labelCls}>{label}</span>
          ) : (
            label
          )}
          {iconPosition === 'right' && icon}
        </>
      )}
    </button>
  );
};

export default Button;

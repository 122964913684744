const Upload = () => {
  return (
    <svg width={79} height={79} fill="none">
      <rect x={6} y={6} width={67} height={67} rx={33.5} fill="#EAECF0" />
      <g clipPath="url(#prefix__clip0_762_7393)">
        <path
          d="M45.084 45.084L39.5 39.5m0 0l-5.583 5.584M39.5 39.5v12.563m11.711-3.643a6.98 6.98 0 00-3.336-13.107h-1.758a11.166 11.166 0 10-19.18 10.19"
          stroke="#475467"
          strokeWidth={2.792}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x={6}
        y={6}
        width={67}
        height={67}
        rx={33.5}
        stroke="#F8F8F8"
        strokeWidth={10.05}
      />
      <defs>
        <clipPath id="prefix__clip0_762_7393">
          <path
            fill="#fff"
            transform="translate(22.75 22.75)"
            d="M0 0h33.5v33.5H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Upload;

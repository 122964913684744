import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { AuthGuard, SubscriptionGuard } from '@/lib/guards';
import SubscriptionExpiredMessageWrapper from '@/pages/other/LockedSubscription/SubscriptionExpiredMessageWrapper';

import type { RouteType } from '.';

const NotFound = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/other/NotFound')), 500);
  });
});

const Welcome = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/other/Welcome')), 500);
  });
});

const Forbidden = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    resolve(import('@/pages/other/Forbidden'));
  });
});

const Invitation = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    resolve(import('@/pages/other/Invitation'));
  });
});

const LockedSubscription = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    resolve(import('@/pages/other/LockedSubscription'));
  });
});

const routes: RouteType[] = [
  {
    element: <Welcome />,
    path: '/',
  },
  { element: <Invitation />, path: '/invitation' },
  { element: <NotFound />, path: '*' },
  { element: <Forbidden />, path: '/forbidden' },
  {
    children: (
      <>
        <Route index path="" element={<Navigate to="/not-found" />} />
        <Route
          path="sub"
          element={
            <SubscriptionGuard>
              <SubscriptionExpiredMessageWrapper />
            </SubscriptionGuard>
          }
        />
      </>
    ),
    element: (
      <AuthGuard>
        <LockedSubscription />
      </AuthGuard>
    ),
    path: 'locked',
  },
];

export default routes;

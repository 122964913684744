import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { WizardLayout } from '@/components/layouts';
import { AuthGuard } from '@/lib/guards';

import type { RouteType } from '.';

const CompanyInfo = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/wizard/CompanyInfo')), 500);
  });
});

const BankAccounts = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/wizard/BankAccounts')), 500);
  });
});

const Subscription = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/wizard/Subscription')), 500);
  });
});

const PaymentSuccess = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/wizard/PaymentSuccess')), 500);
  });
});

const outletElements = [
  {
    element: <Navigate to="company-info" />,
    href: '',
  },
  {
    element: <CompanyInfo />,
    href: 'company-info',
  },
  {
    element: <BankAccounts />,
    href: 'bank-accounts',
  },
  {
    element: <Subscription />,
    href: 'subscription',
  },
  {
    element: <PaymentSuccess />,
    href: 'payment-success',
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <AuthGuard>
        <WizardLayout />
      </AuthGuard>
    ),
    path: 'wizard',
  },
];

export default routes;

import type { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { Navbar, Sidebar } from '@/components/layouts';

interface SettingsProps {}

const Settings: FC<SettingsProps> = () => {
  return (
    <div className="min-h-[100vh] flex-col flex">
      <Navbar />

      <div className="grow bg-sec flex">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;

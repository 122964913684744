import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import {
  type CompanySubscription,
  CompanySubscriptionStatus,
} from '@/store/reducers';

import { useAppSelector } from '../../hooks';

interface SubscriptionGuardProps {
  children: JSX.Element | JSX.Element[];
}

const SubscriptionGuard: FC<SubscriptionGuardProps> = ({ children }) => {
  const companySubscription: CompanySubscription | null = useAppSelector(
    (store) => store.global.companySubscription
  );

  // Derived State
  const isAppLocked = companySubscription
    ? [
        CompanySubscriptionStatus.EXPIRED,
        CompanySubscriptionStatus.SUSPENDED,
      ].includes(companySubscription.status)
    : true;

  if (isAppLocked && location.pathname !== '/locked/sub') {
    // User's subscription is expired or suspended, and they're not trying to access "/locked/sub"
    return <Navigate to="/locked/sub" />;
  } else if (!isAppLocked && location.pathname === '/locked/sub') {
    // User has a valid subscription but is trying to access "/locked/sub"
    return <Navigate to="/" />;
  } else {
    return <>{children}</>;
  }
};

export default SubscriptionGuard;

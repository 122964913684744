import type { FC } from 'react';

import { dark, light } from '@/assets/images';
import { useAppTheme } from '@/hooks';

interface ThemeButtonProps {}

const ThemeButton: FC<ThemeButtonProps> = () => {
  const { theme, toggleTheme } = useAppTheme();

  return (
    <>
      {theme === 'dark' ? (
        <button
          onClick={toggleTheme}
          className="flex items-center cursor-pointer bg-sec rounded-full w-12 h-6"
        >
          <div className="mr-1 items-center flex justify-end w-full rounded-full duration-300">
            <img className="w-[20px]" alt="light" src={light} />
          </div>
        </button>
      ) : (
        <button
          onClick={toggleTheme}
          className="flex items-center cursor-pointer bg-sec rounded-full w-12 h-6"
        >
          <div className="bg-first ml-1 items-center flex rounded-full duration-300">
            <img className="w-[20px]" alt="dark" src={dark} />
          </div>
        </button>
      )}
    </>
  );
};

export default ThemeButton;

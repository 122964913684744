import axios from 'axios';

import {
  defaultAPIRequestMiddleware,
  defaultAPIResponseMiddleware,
} from '@/lib/interceptors';

const serverURL = window.ENV[process.env.NODE_ENV || 'development'].apiPayment;

const paymentAPI = axios.create({
  baseURL: serverURL,
});

paymentAPI.interceptors.request.use(defaultAPIRequestMiddleware, (error) =>
  Promise.reject(error)
);

paymentAPI.interceptors.response.use(
  (res) => res,
  (error) => defaultAPIResponseMiddleware(error, paymentAPI)
);

export default paymentAPI;

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';

import { errorIcon } from '@/assets/images';
import { ImageService } from '@/services';

import { getCls } from '..';

const DEFAULT_CLS = 'h-auto w-auto';
const DEFAULT_CLS_CONTAINER = 'h-auto w-auto flex items-center justify-center';

enum ImageStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface ImageProps {
  /**
   * @default ""
   */
  url: string;

  /**
   * @default image
   */
  alt: string;

  /**
   * @default ""
   */
  cls?: string;

  /**
   * @default ""
   */
  clsContainer?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultClsContainer?: boolean;
}

/**
 *
 * @param  {string} url image url
 * @param {string} cls image className for custom styling
 * @param {string} alt image alt
 * @example <Image url="image.png" cls="image-style" alt="avatar" />
 */
const Image: FC<ImageProps> = ({
  url,
  cls = '',
  clsContainer = '',
  alt = 'image',
  applyDefaultCls = true,
  applyDefaultClsContainer = true,
}) => {
  const [imageStatus, setImageStatus] = useState(ImageStatus.INITIAL);
  const [image, setImage] = useState('');

  cls = getCls(applyDefaultCls, DEFAULT_CLS, cls);
  clsContainer = getCls(
    applyDefaultClsContainer,
    DEFAULT_CLS_CONTAINER,
    clsContainer
  );

  const getImageResource = () => {
    setImageStatus(ImageStatus.IN_PROGRESS);
    ImageService.getImage(url)
      .then((res) => {
        const imageURL = URL.createObjectURL(res.data);

        setImage(imageURL);

        setImageStatus(ImageStatus.SUCCESS);
      })
      .catch(() => {
        setImageStatus(ImageStatus.ERROR);
      });
  };

  const renderSwitch = () => {
    switch (imageStatus) {
      case 'ERROR':
        return <ImageError />;

      case 'IN_PROGRESS':
        return <RotatingLines visible={true} strokeColor="grey" width="30" />;

      case 'SUCCESS':
        return <img src={image} className={cls} alt={alt} />;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    getImageResource();
  }, [url]);

  return <div className={clsContainer}>{renderSwitch()}</div>;
};

const ImageError = () => {
  return (
    <div className="flex flex-col items-center w-auto h-auto gap-2">
      <img className="max-w-8 max-h-8" src={errorIcon} alt="error" />
    </div>
  );
};

export default Image;

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';
import { pdfjs } from 'react-pdf';

import { empty } from '@/assets/images/dashboard';
import { getCls } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { ImageService } from '@/services';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const DEFAULT_CLS = 'h-auto w-auto';
const DEFAULT_CLS_CONTAINER = 'h-auto w-auto flex items-center justify-center';

enum PdfStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface PdfProps {
  /**
   * @default ""
   */
  url: string;

  /**
   * @default ""
   */
  cls?: string;

  /**
   * @default ""
   */
  clsContainer?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultClsContainer?: boolean;
}

/**
 *
 * @param  {string} url pdf url
 * @param {string} cls pdf className for custom styling
 * @example <pdf url="pdf.png" cls="pdf-style" alt="avatar" />
 */
const ObjectPdf: FC<PdfProps> = ({
  url,
  cls = '',
  clsContainer = '',
  applyDefaultCls = true,
  applyDefaultClsContainer = true,
}) => {
  const [pdfStatus, setPdfStatus] = useState(PdfStatus.INITIAL);

  cls = getCls(applyDefaultCls, DEFAULT_CLS, cls);
  clsContainer = getCls(
    applyDefaultClsContainer,
    DEFAULT_CLS_CONTAINER,
    clsContainer
  );
  const { t } = useAppTranslation();
  const [pdfReader, setPdfReader] = useState('');

  const getPdfRessource = () => {
    setPdfStatus(PdfStatus.IN_PROGRESS);
    ImageService.getImage(url)
      .then((res) => {
        const pdfURL = URL.createObjectURL(res.data);
        setPdfReader(pdfURL);
        setPdfStatus(PdfStatus.SUCCESS);
      })
      .catch(() => {
        setPdfStatus(PdfStatus.ERROR);
      });
  };

  const renderSwitch = () => {
    switch (pdfStatus) {
      case 'ERROR':
        return (
          <div className="w-full h-full flex items-center justify-center flex-col py-16">
            <img alt="Empty" src={empty} />
            <div className="text-center w-full h-[20vh] flex flex-col gap-2">
              <span className="text-slate-800 text-[25px] font-bold">
                {t('No document available in pdf')}
              </span>

              <div className="flex items-center justify-center">
                <p className="text-slate-500 max-w-[500px]">
                  {t('error msg pdf')}
                </p>
              </div>
            </div>
          </div>
        );

      case 'IN_PROGRESS':
        return (
          <div className="w-full h-[60vh] flex items-center justify-center flex-col py-16">
            <RotatingLines visible={true} strokeColor="grey" width="30" />
          </div>
        );

      case 'SUCCESS':
        return (
          <div className={clsContainer}>
            <object data={pdfReader} type="application/pdf" className={cls}>
              This is the PDF content.
            </object>
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    getPdfRessource();
  }, [url]);

  return <div className={clsContainer}>{renderSwitch()}</div>;
};

export default ObjectPdf;

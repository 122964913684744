import type { TranslationsType } from '..';

const FR_TRANSLATIONS: TranslationsType = {
  // ==============================
  // App - Auth - SignUp
  // ==============================
  Register: "S'inscrire",
  'Create your account and manage your accounting like a pro':
    'Créons votre compte et gérez votre comptabilité comme un pro',
  'First Name': 'Prénom',
  'Last Name': 'Nom',
  Email: 'Email',
  Password: 'Mot de passe',
  'Confirm password': 'Confirmer le mot de passe',
  'I accept the terms and conditions and the privacy policy.':
    "J'accepte les conditions générales et la politique de confidentialité.",
  Validate: 'Valider',
  'Sign in with Gmail': 'Connectez-vous avec Gmail',
  'Already have an account?': 'Vous avez déjà un compte ?',
  'Sign In': 'Se connecter',
  'The password must be valid.':
    'Le mot de passe doit comporter au moins 8 caractères, et inclure au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
  'Passwords do not match.': 'Les mots de passe ne correspondent pas.',
  'Please enter your first name.': 'Veuillez taper votre prénom.',
  'Please enter your last name.': 'Veuillez taper votre nom.',
  'This email already exists.': 'Cet email existe déjà.',
  // ==============================

  // ==============================
  // App - Auth - ResetPassword
  // ==============================
  'Reset your password': 'Réinitialisez votre mot de passe',
  'The link will be sent to your email address.':
    'Le lien sera envoyé à votre adresse e-mail.',
  // 'Email': 'Email',
  'Please enter a valid email address.':
    'Veuillez entrer une adresse e-mail valide.',
  'Submit Reset': 'Lancer la réinitialisation',
  'An email has been sent to the entered address.':
    "Un email a été envoyé à l'adresse saisie.",
  "Don't have an account yet?": "Vous n'avez pas encore un compte ?",
  'Enter a new password for your account.':
    'Veuillez saisire un nouveau mot de passe pour votre compte.',
  'Reset password link is invalid or has expired':
    "Le lien de réinitialisation du mot de passe n'est pas valide ou a expiré.\n" +
    'Cliquez sur le bouton ci-dessous pour demander un nouvel e-mail de réinitialisation.',
  'The reset password link has expired.':
    'Le lien de réinitialisation du mot de passe a expiré.',
  'The reset password link is already used.':
    'Le lien de réinitialisation du mot de passe est déjà utilisé.',
  'The reset password link is invalid.':
    "Le lien de réinitialisation du mot de passe n'est pas valide.",
  // 'Register': "S'inscrire",
  // ==============================

  // ==============================
  // App - Auth - SignIn
  // ==============================
  // 'Sign In': 'Se connecter',
  // Email: 'Email',
  // Password: 'Mot de passe',
  'Stay connected': 'Rester connecté',
  'Forgot your password?': 'Mot de passe oublié ?',
  // 'Sign In': 'Se connecter',
  // 'Sign in with Gmail': 'Connectez-vous avec Gmail',
  // "Don't have an account yet?": "Vous n'avez pas encore un compte ?",
  // Validate: 'Valider',
  'Email or password is incorrect.': 'Email ou mot de passe incorrect.',
  'This email is locked.': 'Cet e-mail est verrouillé.',
  'Your email has not been verified yet.':
    "Votre email n'a pas encore été vérifié.",
  'Welcome back to login. As a returning customer, you have access to all your previously saved information.':
    'Bienvenue à nouveau pour vous connecter. En tant que client récurrent, vous avez accès à toutes vos informations précédemment enregistrées.',
  // 'Please enter a valid email address.':
  //   'Veuillez entrer une adresse e-mail valide.',
  // 'Password must be between 8 and 32 characters.':
  //   'Le mot de passe doit comporter entre 8 et 32 caractères.',
  // ==============================

  // ==============================
  // App - Wizard
  // ==============================
  'Company Information': 'Informations société',
  'Bank Accounts': 'Comptes bancaires',
  Subscription: 'Abonnement',
  'Bank account deleted successfully': 'Compte bancaire supprimé avec succès',
  'Thank you for your payment!': 'Merci pour votre paiement !',
  'Go to dashboard': 'Aller au tableau de bord',
  'We sent you an email': 'Nous vous avons envoyé un e-mail',
  // ==============================

  // ==============================
  // App - Wizard - CompanyInfo
  // ==============================
  'Welcome to Bore!': 'Bienvenue sur Bore!',
  "Sign up now, it's free and only takes two minutes to manage your accounts.":
    "Inscrivez-vous maintenant, c'est gratuit et ne prend que deux minutes pour gérer votre comptabilité.",
  'Company Name': "Nom de l'entreprise",
  'Please enter your company name.':
    'Veuillez entrer le nom de votre entreprise.',
  'Searching for the company...': "A la recherche de l'entreprise...",
  'Type your company name.': 'Taper le nom de votre entreprise.',
  'ex. abyCompany': 'ex. abyCompany',
  'Company Form': 'Forme de l’entreprise',
  'Please select your company form.':
    'Veuillez sélectionner la forme de votre entreprise.',
  'ex. SARL': 'ex. SARL',
  'Company Address': 'Adresse de l’entreprise',
  'ex. North Urban Center': 'ex. Centre Urbain Nord',
  'Please enter your company address.':
    "Veuillez entrer l'adresse de votre entreprise.",
  'ex. Tunis': 'ex. Tunis',
  'Company Logo': 'Logo de l’entreprise',
  'Click to upload': 'Cliquez pour uploader',
  'or drag & drop': 'ou glisser-déposer',
  'SVG, PNG, JPG or GIF (max. 800x400px)':
    'SVG, PNG, JPG ou GIF (max. 800x400px)',
  Next: 'Suivant',
  Previous: 'Précédent',
  'Single-Person Limited Liability Company (SUARL)':
    'Société Unipersonnelle à Responsabilité Limitée (SUARL)',
  'Limited Liability Company (SARL)': 'Société à Responsabilité Limitée (SARL)',
  'Public limited company (SA)': 'Société Anonyme (SA)',
  'Tax Number': "Numéro d'identification fiscale",
  'Please enter your company Tax Number.':
    'Veuillez saisir le numéro fiscal de votre entreprise.',
  Company: 'Entreprise',
  'Add a new company': 'Ajouter une nouvelle entreprise',
  // ==============================

  // ==============================
  // App - Wizard - BankAccounts
  // ==============================
  // 'Welcome to Bore!': 'Bienvenue sur Bore !',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Inscrivez-vous maintenant, c'est gratuit et ne prend que deux minutes pour gérer votre comptabilité.",
  'Bank details': 'RIB',
  'Please type your RIB.': 'Veuillez tapez votre RIB.',
  'The RIB must contain 20 digits.': 'Le RIB doit contenir 20 chiffres.',
  'This bank account is already added to your list.':
    'Ce compte bancaire est déjà ajouté à votre liste.',
  'You can have a maximum of five bank accounts.':
    'Vous pouvez avoir un maximum de cinq comptes bancaires.',
  'Please verify your RIB.': 'Veuillez vérifier votre RIB.',
  'Please check your bank.': 'Veuillez vérifier votre bank.',
  'ex. 20 digits': 'ex. 20 chiffres',
  'ex. WeCraft': 'ex. WeCraft',
  Currency: 'Devise',
  'Please select a currency.': 'Veuillez sélectionner un devise.',
  'Add a bank': 'Ajouter une banque',
  'List of added bank accounts': 'Liste des comptes bancaires ajoutés',
  // 'Next': 'Suivant',
  'Error occured.': 'Erreur est survenue.',
  // ==============================

  // ==============================
  // App - Wizard - Subscription
  // ==============================
  // 'Welcome to Bore!': 'Bienvenue sur Bore !',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Inscrivez-vous maintenant, c'est gratuit et ne prend que deux minutes pour gérer votre comptabilité.",
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PricingPlans
  // ==============================
  'Basic plan': 'Forfait de base',
  'Business plan': "Plan d'affaires",
  'Enterprise plan': "Plan d'entreprise",
  'Per month': 'Par mois',
  Start: 'Commencer',
  Default: 'Défaut',
  Free: 'Gratuit',
  Trail: 'Essai',
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PaymentForm
  // ==============================
  'Billing Information': 'Détails de facturation',
  'Firstname *': 'Prénom *',
  Firstname: 'Prénom',
  'Lastname *': 'Nom *',
  Lastname: 'Nom',
  'Company Name *': 'Nom de l’entreprise *',
  // 'Company Name': 'Company de l’entreprise',
  'Country / Region *': 'Pays / Région *',
  'Country / Region': 'Pays / Région',
  'Postal Code *': 'Code postal *',
  'Phone *': 'Téléphone *',
  'Address *': 'Adresse *',
  Address: 'Adresse',
  // 'Start': 'Commencer',
  'Your order': 'Votre commande',
  VAT: 'TVA',
  Total: 'Total',
  'Pay by credit card with e-dinar card or with your Paymee account.':
    'Payer par carte bancaire avec carte e-dinar ou avec votre compte Paymee.',
  Order: 'Commander',
  'Invoice details': 'Détails de la facture',
  'Your command': 'Votre commande',
  'Please enter a firstname.': 'Veuillez saisir un prénom.',
  'Please enter a lastname.': 'Veuillez saisir un nom.',
  'Please enter a company name.': "Veuillez saisir un nom d'entreprise.",
  'Please enter a country and/or region.':
    'Veuillez entrer un pays et/ou une région.',
  'Please enter a valid postal code.': 'Veuillez entrer un code postal valide.',
  'Please enter a valid phone number.':
    'Veuillez entrer un numéro de téléphone valide.',
  'Please enter an address.': 'Veuillez entrer une adresse.',
  // ==============================

  // ==============================
  // App - Dashboard
  // ==============================
  'Find your company': 'Chercher votre entreprise',
  Light: 'Clair',
  Dark: 'Sombre',
  Performances: 'Performances',
  Receipts: 'Justificatifs',
  Transactions: 'Transactions',
  Statements: 'Relevés',
  Documents: 'Documents',
  Notifications: 'Notifications',
  Settings: 'Paramètres',
  'Drag & drop': 'Glisser-déposer',
  'PNG, JPG, PDF (max. 900 MB)': 'PNG, JPG, PDF (max. 900 MB)',
  'Upload a file': 'Uploader un fichier',
  "Can't upload statement": 'Impossible de télécharger le relevé',
  'Statement does not exist': "le relevé n'existe pas",
  "Image can't be loaded, please check your internet connection":
    "Impossible de charger l'image, veuillez vérifier votre connexion Internet",
  'Add a new statement': 'Ajouter une nouvelle déclaration',
  'Processing error': 'Erreur de traitement',
  'Being analyzed': "En cours d'analyse",
  'Something went wrong please contact support for help.':
    "Un problème s'est produit. Veuillez contacter le service d'assistance pour obtenir de l'aide.",
  'Contact support': 'Contacter le support',
  'Please check the bank statement you uploaded':
    "Veuillez vérifier le relevé bancaire que vous avez téléchargé, il semble que vous ayez téléchargé le mauvais relevé ou qu'il manque des informations importantes.",
  'Delete statement': 'Supprimer la déclaration',
  'Return to transactions': 'Retour aux transactions',
  'Add Manual Transaction': 'Ajouter une transaction',
  ' Channel ': 'Canal',
  'Credit/Debit': 'Crédit/Débit',
  'Transaction type': 'Type de transaction',
  Title: 'Titre',
  'Payment channel is required': 'Canal de paiement obligatoire',
  'ShareholderUser is required': 'Un assoicié est nécessaire',
  'Money flow is required': "Flux d'argent obligatoire",
  'Title is required': 'Titre obligatoire',
  'Transaction date is required': 'Date de transaction obligatoire',
  'Amount is required': 'Montant obligatoire',
  'Amount must be greater than 0': 'Montant doit être supérieur à 0',
  'Currency is required': 'Devise obligatoire',
  'Add a new transaction': 'Ajouter une nouvelle transaction',
  'Successfully Added': 'Ajouté avec Succés',
  'The new transaction is now part of the existing list, seamlessly integrated.':
    'La nouvelle transaction fait désormais partie de la liste existante, parfaitement intégrée.',
  'Thank you for adding a new transaction':
    "Merci d'avoir ajouté une nouvelle transaction",
  // ==============================
  // App - Dashboard - Export fille
  // ==============================
  Download: 'Télécharger',
  Completed: 'Terminé',
  Files: 'Dossiers',
  'Exported data': 'Données exportées',
  'Choice date range': 'Choix de la date',
  'Account verification': 'Vérification du compte',
  'Enter your password': 'Entrez votre mot de passe',
  'Auth verification': "Vérification de l'identité",
  'Export fille': "Fichier d'exportation",
  'Export Data Page': "Page d'exportation des données",
  // ==============================

  // ==============================
  // App - Dashboard - Home
  // ==============================
  'Hello, world!': 'Bonjour, le monde!',
  // ==============================

  // ==============================
  // App - Settings
  // ==============================
  'Settings (02)': 'Paramétrages',
  Profile: 'Profil',
  Surname: 'Nom de famille',
  'Phone number': 'Numéro de téléphone',
  Enterprise: 'Entreprise',
  'Profile updated successfully.': 'Profil mis à jour avec succès.',
  'Legal Status': 'Statut juridique ',
  'Tax id': 'Identifiant fiscal ',
  'Password Page': 'Page de mot de passe',
  'Old Password': 'Ancien mot de passe',
  'New Password': 'Nouveau mot de passe',
  'Confirm new password': 'Confirmer le nouveau mot de passe',
  'Save changes': 'Enregistrer les modifications',
  Team: 'Équipe',
  // 'Subscription': 'Subscription',
  'Last Access Date': 'Date du dernier accès',
  'Invite a user': 'Inviter un utilisateur',
  Name: 'Nom',
  'Please enter a valid name.': 'Veuillez saisir un nom valide.',
  'Subscription ends on': "L'abonnement se termine le",
  MONTH: 'Par mois',

  // ==============================
  // App - Performance
  // ==============================
  Turnover: "Chiffre d'affaires",
  Charge: 'Charge',
  'Operating result': "Résultat d'exploitation",
  Treasury: 'Trésorerie',
  'Compared to': 'Comparé à',
  'last year': "l'année dernière",

  //****** */
  // ==============================

  // ==============================
  // App - Receipts
  // ==============================
  Status: 'Statut',
  Amount: 'Montant',
  'Total Amount': 'Montant total',
  All: 'Tous',
  'Not associated/analyzed': 'Non associé/analysé',
  Associated: 'Associé',
  'Not associated': 'Non associé',
  Filter: 'Filtre',
  Category: 'Catégorie',
  'Delete filters': 'Supprimer les filtres',
  Apply: 'Appliquer',
  Search: 'Recherche',
  From: 'Fournisseur',
  Supplier: 'Fournisseur',
  'Payment Method': 'Mode de paiement',
  totalAmount: 'Montant total',
  'All Taxes Included': 'Toutes taxes incluses',
  'Add a VAT line': 'Ajouter une ligne TVA',
  invoiceDate: 'Date de facturation',
  vatAmount: 'Montant',
  vatTaxAmount: 'Taxe sur la TVA',
  vatTaxRate: 'Taux en %',
  'My receipt': 'Mes justificatifs',
  Aucune: 'Aucune',
  Aucun: 'Aucun',
  'Not analyzed': 'Non analysé',
  'No data table': "Aucun justificatif n'a été trouvé",
  Max: 'Max',
  Min: 'Min',
  'Add supporting receipts': 'Ajouter les justificatifs',
  FILE_IS_EMPTY: 'Le fichier est vide',
  'Next step': 'Continuer',
  Upload: 'Uploader',
  'Document providing physical proof that something has been done':
    'Document apportant la preuve physique que quelque chose a été réalisé',
  'error msg pdf':
    "Il semble que le pdf du justificatif n'est pas disponible, essayez de télécharger à nouveau le document.",
  'No document available in pdf': "Aucun document n'est disponible en pdf",
  'error msg image':
    "Il semble que l'image du justificatif n'est pas disponible, essayez de télécharger à nouveau le document.",
  'No image available': 'Aucune image disponible',
  Downloads: 'Téléchargements',
  Analysis: 'Analyse',
  Done: 'Terminé',
  Uploaded: 'Téléchargé',
  UPLOADED: 'Téléchargé',
  'Your file is not valid.': 'Votre fichier est invalide.',
  'The receipt is validated': 'Le justificatif est validé',
  NOT_ASSOCIATED: 'Non associé',
  NOT_ANALYZED: 'Non analysé',
  ANALYSIS_FAILURE: 'Analyse échouée',
  ANALYZED: 'Analysé',
  ANALYSIS_SUCCESS: 'Analysé',
  ANALYSIS_IN_PROGRESS: 'Analyse en cours',
  ASSOCIATED: 'Associé',
  VALIDATED: 'Validée',
  NOT_PAID: 'Non payée',
  PAID: 'Payée',
  'Back to receipts': 'Retour aux justificatifs',
  'View in fullscreen': 'Voir en plein écran',
  'Sales invoice': 'Facture de vente',
  'Purchase invoice': "Facture d'achat",
  'Invoice Type': 'Type de facture',
  TVA: 'TVA',
  'Date not found': 'Date non trouvée',
  "You're about to delete this receipt, are you sure ?":
    'Vous êtes sur le point de supprimer ce justificatif, êtes-vous sûr ?',
  'Delete receipt': 'Supprimer le justificatif',
  'Delete all receipts': 'Supprimer tous les justificatifs',
  "You're about to delete all the selected receipts, are you sure?":
    'Vous êtes sur le point de supprimer tous les justificatifs sélectionnés, êtes-vous sûr?',
  'date not found': 'Date non trouvée',

  // 'Team': 'Team',
  // 'Subscription': 'Subscription',
  // ==============================
  // App - Transactions
  // ==============================
  Filters: 'Filtres',
  Sort: 'Trier',
  by: 'par',
  Date: 'Date',
  'Sale of': 'Vente de',
  Service: 'Service',
  'Operations to validate': 'Opérations à valider',
  'Validated operations': 'Opérations validées',
  Operations: 'Opérations',
  'Change my plan': 'Changer mon forfait',
  'Validate the operation': "Valider l'opération",
  'To categorize': 'À catégoriser',
  Invalidate: 'Annuler la validation',
  Invalidated: 'Non validée',
  'Validate by Owner': 'Validé par le propriétaire',
  'Validate by accoutant': 'Validé par le comptable',
  'The transaction is validated': 'La transaction est validée',
  'The transaction is invalidated':
    'la validation de la transcation est annulée',
  'Error validating transaction': 'Erreur dans la validation de la transaction',
  'Account balances': 'Soldes des comptes',
  'Software subscription': 'Abonnement logiciel',
  'Deposit received': 'Caution reçue',
  'VAT paid': 'TVA payée',
  'To choose': 'À choisir',
  Perhaps: 'Peut-être?',
  // ==============================
  // App - Other - Global Error Handler
  // ==============================
  'Server error. Please try again later.':
    'Erreur au niveau du serveur. Veuillez réessayer plus tard.',
  'Service Unavailable. Please try again later.':
    'Service non disponible. Veuillez réessayer plus tard.',
  Unauthorized: 'Non autorisé',
  "You don't have permissions to access this resource.":
    "Vous n'êtes pas autorisé à accéder à cette ressource.",
  'Timeout error': "Erreur d'expiration du délai",
  // ==============================

  // ==============================
  // App - Other - Error Boundary
  // ==============================
  'Unexpected Error Occured': "Une erreur inattendue s'est produite",
  // ==============================

  // ==============================
  // App - Other - Subscription Expired
  // ==============================
  'Subscription Expired': 'Votre abonnement a expiré',
  'Subscription Expired Description':
    'Votre abonnement a expiré, merci de cliquer sur Renouveler mon abonnement pour réactiver votre compte.',
  'Renew My Subscription': 'Renouveler mon abonnement',
  'Subscription Expiry Approaching':
    "L'abonnement arrive bientôt à expiration, merci de cliquer sur Renouveler mon abonnement.",
  'Subscription Days Left': 'Expire dans {{days}} jours',
  'Expires in less than 1 day': "Expire dans moins d'un jour",
  // ==============================

  // ==============================
  // App - Other - Not Found
  // ==============================
  'Page Not Found': 'Page non trouvée',
  // ==============================

  // App - Handle errors
  // ==============================
  AUTH_SIGNIN_BAD_CREDENTIALS: "L'e-mail ou le mot de passe est incorrect.",
  AUTH_SIGNIN_USER_LOCKED: 'Ce compte est actuellement verrouillé',
  AUTH_SIGNIN_USER_NOT_ACTIVATED: "Ce compte n'est pas activé",
  AUTH_SIGNIN_INVALID_RECAPTCHA_TOKEN:
    'Le jeton captcha est invalide ou a expiré',

  AUTH_SIGNUP_EMAIL_ALREADY_EXISTS:
    'Cet email est déjà utilisé dans un autre compte',
  INTERNAL_ERROR: "Une erreur de serveur interne s'est produite",
  COMPANY_COMPANY_EXIST: "L'entreprise existe déjà",
  COMPANY_ID_NOT_FOUND: 'Utilisateur non trouvé',
  COMPANY_INVALID_CREATE_COMPANY_REQUEST:
    "Quelque chose s'est mal passé, veuillez revérifier les informations de votre entreprise.",
  SETTINGS_ID_MEMBER_NOT_FOUND: 'Le membre est introuvable.',
  SETTINGS_COMPANY_NOT_FOUND: 'La société est introuvable.',
  SETTINGS_INVITATION_EXISTS:
    "Une invitation a déjà été envoyée à l'adresse email fournie.",
  SETTINGS_MEMBER_EXISTS: "Un membre avec l'email fourni existe déjà",
  SETTINGS_ID_NOT_FOUND:
    "L'identifiant d'utilisateur n'appartient à aucun compte",
  UNAUTHORIZED_UNAUTHORIZED: "L'accès non autorisé",
  'Oops! Page not found': 'Oups!\n Page non trouvée',
  "This page doesn't exist or get removed. We suggest you back to home":
    "Cette page n'existe pas ou a été supprimée. Nous vous suggérons de retourner à l'accueil.",
  'Go back home': "Retour à l'accueil",
  'Access denied': 'Oups!\n Accès refusé',
  "You don't have permission to access requested page.":
    "Vous n'avez pas l'autorisation d'accéder à la page demandée. Nous vous suggérons de retourner à l'accueil.",
  'Oh no!': 'Oh non!',
  'So sorry, something not working well please try again later or feel free to contact support.':
    "Désolé, quelque chose ne fonctionne pas bien. Veuillez réessayer plus tard ou n'hésitez pas à contacter le service d'assistance.",
  'Something went wrong': "Quelque chose n'a pas fonctionné",
  // ==============================
  // Month
  // ==============================
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',
  // ==============================
};

export default FR_TRANSLATIONS;

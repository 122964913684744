import { saasAPI } from './apis';

export default class SaasService {
  static getConnectedUser(signal?: AbortSignal) {
    return saasAPI.get(`/v1/auth/me`, {
      signal,
    });
  }

  static refreshAccessToken(signal?: AbortSignal) {
    return saasAPI.post(`/v1/auth/refresh`, null, {
      signal,
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: true,
    });
  }

  static checkPassword(password: string, signal?: AbortSignal) {
    const data = {
      password: password,
    };

    return saasAPI.post('/v1/auth/check-password', data, {
      signal,
    });
  }

  static async getMembersByCompanyId(signal?: AbortSignal) {
    return saasAPI.get(`/v1/members`, {
      signal,
    });
  }
}

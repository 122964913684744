import axios from 'axios';

import {
  defaultAPIRequestMiddleware,
  defaultAPIResponseMiddleware,
} from '@/lib/interceptors';

const BASE_URL =
  window.ENV[process.env.NODE_ENV || 'development'].apiBankingUrl;

const bankingAPI = axios.create({
  baseURL: BASE_URL,
});

bankingAPI.interceptors.request.use(defaultAPIRequestMiddleware, (error) =>
  Promise.reject(error)
);

bankingAPI.interceptors.response.use(
  (res) => res,
  (error) => defaultAPIResponseMiddleware(error, bankingAPI)
);

export default bankingAPI;

import type { InviteUserFormType } from '@/lib/forms';
import type {
  ResetPasswordFormType,
  SignInFormType,
  SignUpFormType,
  UpdatePasswordToSubmitType,
} from '@/lib/forms/auth-forms';

import { authAPI } from './apis';
import type { TeamType } from './models';

export default class AuthService {
  static signIn(
    data: SignInFormType,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/auth/sign-in`, data, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
      },
      withCredentials: true,
    });
  }

  static signUp(
    data: SignUpFormType,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/auth/sign-up`, data, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
      },
    });
  }

  static resetPassword(
    data: ResetPasswordFormType,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/auth/password/forgot`, data, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
      },
    });
  }

  static updatePassword(
    data: UpdatePasswordToSubmitType,
    signal?: AbortSignal
  ) {
    return authAPI.put(
      `/v1/auth/password/reset/${data.resetToken}`,
      { password: data.confirmedPassword },
      {
        signal,
        headers: {
          'X-recaptcha-token': data.recaptchaToken,
        },
      }
    );
  }

  static verifyResetToken(
    data: { resetToken: string },
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/auth/password/verify-token`, null, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
        'Content-Type': 'application/json',
      },
      params: {
        token: data.resetToken,
      },
    });
  }

  static inviteUser(data: InviteUserFormType, signal?: AbortSignal) {
    return authAPI.post(`/v1/invite`, data, {
      signal,
    });
  }

  static getInvitedMembers(signal?: AbortSignal) {
    return authAPI.get<TeamType>(`/v1/invite/team`, {
      signal,
    });
  }

  static acceptInvitation(
    token: string,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/invite/accept?token=${token}`, null, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
        'Content-Type': 'application/json',
      },
    });
  }

  static rejectInvitation(
    token: string,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/invite/reject?token=${token}`, null, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
        'Content-Type': 'application/json',
      },
    });
  }

  static signUpWithInvitationToken(
    token: string,
    signUpForm: SignUpFormType,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return authAPI.post(`/v1/invite/sign-up?token=${token}`, signUpForm, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
        'Content-Type': 'application/json',
      },
    });
  }

  static verifyInvitationToken(token: string, signal?: AbortSignal) {
    return authAPI.get(`/v1/invite/verify-token?token=${token}`, {
      signal,
    });
  }

  static deleteInvitation(id: string, signal?: AbortSignal) {
    return authAPI.delete(`/v1/invite/${id}`, {
      signal,
    });
  }
}

import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { companyAvatar } from '@/assets/images';
import { chevronDown, plus, search } from '@/assets/images/dashboard';
import { Image, LoadingSpinner } from '@/components/global';
import {
  useAppComponentVisible,
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from '@/hooks';
import { CompanyService } from '@/services';
import { setCompanySubscription } from '@/store/actions/action-creators';

import styles from '../Dashboard/Dashboard.module.scss';

interface CompanyProps {}

interface CompanyType {
  name: string;
  logoUrl: string;
  taxId: string;
  id: string;
}

enum GetCompaniesStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

enum SwitchCompanyStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

const Company: FC<CompanyProps> = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const dispatch = useAppDispatch();

  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);
  const [searchValue, setSearchValue] = useState('');
  const [userCompanies, setUserCompanies] = useState<CompanyType[]>([]);
  const [getCompaniesStatus, setGetCompaniesStatus] = useState(
    GetCompaniesStatus.INITIAL
  );
  const [selectedCompany, setSelectedCompany] = useState<CompanyType>(
    null as unknown as CompanyType
  );
  const [switchCompanyStatus, setSwitchCompanyStatus] = useState(
    SwitchCompanyStatus.INITIAL
  );
  const prevCompanies = useRef<CompanyType[]>(userCompanies);

  const handleToggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setUserCompanies(prevCompanies.current);
    }

    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (isMenuOpen) {
      setSearchValue('');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const filteredCompanies = userCompanies.filter(({ name }) =>
      name.toLowerCase().includes(lowerCaseSearchValue)
    );

    setUserCompanies(filteredCompanies);
  }, [searchValue]);

  useEffect(() => {
    if (!connectedUser.companyId) return;

    const controller = new AbortController();
    setGetCompaniesStatus(GetCompaniesStatus.IN_PROGRESS);

    CompanyService.getUserCompanies(controller.signal)
      .then(({ data }) => {
        data.map((company: CompanyType) => {
          if (company.id === connectedUser.companyId) {
            setSelectedCompany(company);
          }
        });

        setGetCompaniesStatus(GetCompaniesStatus.SUCCESS);
        setUserCompanies(data);
        prevCompanies.current = data;
      })
      .catch(() => {
        setGetCompaniesStatus(GetCompaniesStatus.ERROR);
      });

    return () => controller.abort();
  }, [connectedUser.companyId]);

  const switchCompany = (company: CompanyType) => {
    setSwitchCompanyStatus(SwitchCompanyStatus.IN_PROGRESS);
    CompanyService.switchCompany(company.id)
      .then(({ data }) => {
        setSwitchCompanyStatus(SwitchCompanyStatus.SUCCESS);
        setSelectedCompany(company);
        const accessToken = data.accessToken;
        localStorage.setItem('access-token', accessToken);

        CompanyService.getCompanySubscription()
          .then((companySub) => {
            dispatch(setCompanySubscription(companySub.data));
            window.location.reload();
          })
          .catch(() => setSwitchCompanyStatus(SwitchCompanyStatus.ERROR));
      })
      .catch(() => {
        setSwitchCompanyStatus(SwitchCompanyStatus.ERROR);
      });
  };

  return (
    <div className={styles.company}>
      <div
        className={
          'flex items-center gap-1 cursor-pointer px-2 py-1 rounded-lg border-solid border-2 border-gray-200'
        }
        onClick={handleToggleMenu}
        onBlur={() => setIsMenuOpen(false)}
      >
        {getCompaniesStatus === GetCompaniesStatus.IN_PROGRESS ? (
          <div className="flex items-center justify-center w-full h-[50px]">
            <LoadingSpinner width="30" strokeWidth="1" strokeColor="#5d5ff8" />
          </div>
        ) : (
          <div className="flex w-full justify-between max-w-[250px]">
            <div className="flex items-center gap-1 truncate">
              <Image
                url={
                  selectedCompany?.logoUrl
                    ? selectedCompany?.logoUrl
                    : companyAvatar
                }
                alt="Company Logo"
                cls="object-cover"
                clsContainer="h-[50px] w-[50px] min-w-[50px] rounded-lg overflow-hidden"
              />
              <span className="text-sm font-bold truncate ">
                {selectedCompany?.name}
              </span>
            </div>

            <img src={chevronDown} alt="More" />
          </div>
        )}
      </div>

      {isMenuOpen && (
        <div ref={menuRef} className={styles.menu}>
          <div className={styles.inputContainer}>
            <img src={search} alt="Search" />
            <input
              placeholder="Company name"
              onChange={handleSearch}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>

          {getCompaniesStatus === GetCompaniesStatus.IN_PROGRESS ? (
            <div className="flex items-center justify-center">
              <LoadingSpinner
                width="30"
                strokeWidth="1"
                strokeColor="#5d5ff8"
              />
            </div>
          ) : (
            userCompanies.map((company) => (
              <button
                disabled={
                  company.id === connectedUser.companyId ||
                  switchCompanyStatus === SwitchCompanyStatus.IN_PROGRESS
                }
                onClick={() => switchCompany(company)}
                className={styles.companyItem}
                key={company.taxId}
              >
                <Image
                  url={company.logoUrl ? company.logoUrl : companyAvatar}
                  alt={company.name}
                  clsContainer="h-[30px] w-[30px]"
                />
                <p className="truncate">{company.name}</p>
              </button>
            ))
          )}

          <button
            onClick={() => navigate('/wizard/company-info')}
            className={styles.addCompanyButton}
          >
            <img src={plus} alt="Add" />
            {t('Add a new company')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Company;

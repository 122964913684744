import type { Bank } from '@/lib/forms';

import { settingsAPI } from './apis';
import type { Currency } from './models';

export default class SettingsService {
  static async getBanks(signal?: AbortSignal) {
    return settingsAPI.get<Bank[]>('v1/banks', {
      signal,
    });
  }

  static async getCurrencies(signal?: AbortSignal) {
    return settingsAPI.get<Currency[]>('v1/currencies', {
      signal,
    });
  }

  static async getCurrency(id: string, signal?: AbortSignal) {
    return settingsAPI.get<Currency[]>(`v1/currencies/${id}`, {
      signal,
    });
  }

  static async getCategories(signal?: AbortSignal) {
    return settingsAPI.get('v1/categories', {
      signal,
    });
  }

  static async getPlans(signal?: AbortSignal) {
    return settingsAPI.get('v1/plans', {
      signal,
    });
  }

  static async updatePlan(id: String, signal?: AbortSignal) {
    return settingsAPI.put(`/v1/plans/${id}`, {
      signal,
    });
  }
}

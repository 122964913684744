import { type FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/hooks';
import type { CompanySubscription } from '@/store/reducers';

import { Company, LangSelector, Notification, Profile, ThemeButton } from '.';
import ExpiredPlanButton from './ExpiredPlanButton';

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const companySubscription: CompanySubscription | null = useAppSelector(
    (store) => store.global.companySubscription
  );

  const { t } = useTranslation();

  const { subscriptionTimeLeft, subscriptionDaysLeft } = useMemo(() => {
    let subscriptionTimeLeft = -1;
    if (companySubscription && companySubscription.endDate)
      subscriptionTimeLeft =
        (new Date(companySubscription.endDate).getTime() - Date.now()) /
        MILLISECONDS_IN_DAY;

    return {
      subscriptionTimeLeft,
      subscriptionDaysLeft:
        subscriptionTimeLeft > 0 ? Math.ceil(subscriptionTimeLeft) : 0,
    };
  }, [companySubscription]);

  const { isAlmostExpired, buttonLabel } = useMemo(() => {
    let buttonLabel = t('Subscription Days Left', {
      days: subscriptionDaysLeft,
    });

    if (subscriptionTimeLeft <= 1 && subscriptionTimeLeft > 0)
      buttonLabel = t('Expires in less than 1 day');

    return {
      isAlmostExpired: subscriptionTimeLeft >= 0 && subscriptionTimeLeft <= 7,
      buttonLabel,
    };
  }, [subscriptionTimeLeft]);

  return (
    <div className="fixed top-0 z-50 flex items-center w-full gap-12 pt-4 pb-4 pr-6 bg-first scroll-bar-style z-index ">
      <div className="flex items-center grow">
        <div className="min-w-[250px] flex items-center">
          <div className="w-[80%] ml-auto mr-auto items-center relative bottom-2 ">
            <Company />
          </div>
        </div>
      </div>

      <div className="flex gap-6 items-center">
        {isAlmostExpired && (
          <ExpiredPlanButton
            heading={t('Renew My Subscription')}
            buttonLabel={buttonLabel}
            description={t('Subscription Expiry Approaching')}
          />
        )}
        <ThemeButton />
        <LangSelector />
        <Notification />
        <Profile />
      </div>
    </div>
  );
};

export default Navbar;

import type { FC } from 'react';

import { Switch } from '@mui/material';

import { useAppTheme } from '@/hooks';

interface ThemeSwitchProps {}

const ThemeSwitch: FC<ThemeSwitchProps> = () => {
  const { theme, toggleTheme } = useAppTheme();

  return (
    <div className="w-full flex justify-end absolute">
      <Switch onChange={toggleTheme} checked={theme === 'dark'} />
    </div>
  );
};

export default ThemeSwitch;

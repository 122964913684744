export enum NotificationType {
  BANK_STATEMENT_STATUS_NOTIFICATION = 'BANK_STATEMENT_STATUS_NOTIFICATION',
}

export interface WebSocketNotification {
  type: NotificationType;
  corpus: BankStatementStatusNotification | AnotherExampleOfNotification;
}
export enum BankStatementStatus {
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
  ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
  TREATMENT_PENDING = 'TREATMENT_PENDING',
  ANALYSIS_SUCCESS = 'ANALYSIS_SUCCESS',
  ANALYSIS_FAILURE = 'ANALYSIS_FAILURE',
}

export interface BankStatementStatusNotification {
  companyId: string;
  bankStatementId: string;
  status: BankStatementStatus;
  message: string;
}

export interface AnotherExampleOfNotification {
  companyId: string;
  status: string;
  message: string;
}

export enum CompanySubscriptionStatus {
  SUSPENDED = 'SUSPENDED',
  TEMPORARILY_ACTIVE = 'TEMPORARILY_ACTIVE',
  ACTIVE = 'ACTIVE',
  GRACE_PERIOD = 'GRACE_PERIOD',
  EXPIRED = 'EXPIRED',
}

export interface CompanySubscription {
  id: string;
  planId: string;
  paymentId: string;
  startDate: string;
  endDate: string;
  status: CompanySubscriptionStatus;
}

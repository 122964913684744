const EN_TRANSLATIONS = {
  // ==============================
  // App - Auth - SignUp
  // ==============================
  Register: 'Register',
  'Create your account and manage your accounting like a pro':
    'Create your account and manage your accounting like a pro',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  Email: 'Email',
  Password: 'Password',
  'Confirm password': 'Confirm password',
  'I accept the terms and conditions and the privacy policy.':
    'I accept the terms and conditions and the privacy policy.',
  Validate: 'Validate',
  'Sign in with Gmail': 'Sign in with Gmail',
  'Already have an account?': 'Already have an account?',
  'Sign In': 'Sign In',
  'Passwords do not match.': 'Passwords do not match.',
  'Please enter your first name.': 'Please enter your first name.',
  'Please enter your last name.': 'Please enter your last name.',
  'This email already exists.': 'This email already exists.',
  // ==============================

  // ==============================
  // App - Auth - ResetPassword
  // ==============================
  'Reset your password': 'Reset your password',
  'The link will be sent to your email address.':
    'The link will be sent to your email address.',
  // 'Email': 'Email',
  'Please enter a valid email address.': 'Please enter a valid email address.',
  'Submit Reset': 'Submit Reset',
  'An email has been sent to the entered address.':
    'An email has been sent to the entered address.',
  "Don't have an account yet?": "Don't have an account yet?",
  'Enter a new password for your account.':
    'Enter a new password for your account.',
  'Reset password link is invalid or has expired':
    '      The reset password link is invalid or has expired.\n' +
    'Just click on the button below to request a new reset email.',
  'The reset password link has expired.':
    'The reset password link has expired.',
  'The reset password link is already used.':
    'The reset password link is already used.',
  'The reset password link is invalid.': 'The reset password link is invalid.',
  // 'Register': "Register",
  // ==============================

  // ==============================
  // App - Auth - SignIn
  // ==============================
  // 'Sign In': 'Sign In',
  // Email: 'Email',

  //Password: 'Password',
  'Stay connected': 'Stay connected',
  'Forgot your password?': 'Forgot your password?',
  // 'Sign In': 'Sign In',
  // 'Sign in with Gmail': 'Sign in with Gmail',
  // "Don't have an account yet?": "Don't have an account yet?",
  // Validate: 'Validate',
  'Email or password is incorrect.': 'Email or password is incorrect.',
  'This email is locked.': 'This email is locked.',
  'Your email has not been verified yet.':
    'Your email has not been verified yet.',
  'Welcome back to login. As a returning customer, you have access to all your previously saved information.':
    'Welcome back to login. As a returning customer, you have access to all your previously saved information.',
  // 'Please enter a valid email address.': 'Please enter a valid email address.',
  'The password must be valid.':
    'The password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one digit, and one special character.',
  // ==============================

  // ==============================
  // App - Wizard
  // ==============================
  'Company Information': 'Company Information',
  'Bank Accounts': 'Bank Accounts',
  Subscription: 'Subscription',
  'Bank account deleted successfully': 'Bank account deleted successfully',
  'Thank you for your payment!': 'Thank you for your payment!',
  'Go to dashboard': 'Go to dashboard',
  'We sent you an email': 'We sent you an email',
  // ==============================

  // ==============================
  // App - Wizard - CompanyInfo
  // ==============================
  'Welcome to Bore!': 'Welcome to Bore!',
  "Sign up now, it's free and only takes two minutes to manage your accounts.":
    "Sign up now, it's free and only takes two minutes to manage your accounts.",
  'Company Name': 'Company Name',
  'Please enter your company name.': 'Please enter your company name.',
  'Searching for the company...': 'Searching for the company...',
  'Type your company name.': 'Type your company name.',
  'ex. abyCompany': 'ex. abyCompany',
  'Company Form': 'Company Form',
  'Please select your company form.': 'Please select your company form.',
  'ex. SARL': 'ex. SARL',
  'Company Address': 'Company Address',
  'ex. North Urban Center': 'ex. North Urban Center',
  'Please enter your company address.': 'Please enter your company address.',
  'ex. Tunis': 'ex. Tunis',
  'Company Logo': 'Company Logo',
  'Click to upload': 'Click to upload',
  'or drag & drop': 'or drag & drop',
  'SVG, PNG, JPG or GIF (max. 800x400px)':
    'SVG, PNG, JPG or GIF (max. 800x400px)',
  Next: 'Next',
  Previous: 'Previous',
  'Single-Person Limited Liability Company (SUARL)':
    'Single-Person Limited Liability Company (SUARL)',
  'Limited Liability Company (SARL)': 'Limited Liability Company (SARL)',
  'Public limited company (SA)': 'Public limited company (SA)',
  'Tax Number': 'Tax Number',
  'Please enter your company Tax Number.':
    'Please enter your company Tax Number.',
  Company: 'Company',
  'Add a new company': 'Add a new company',
  // ==============================

  // ==============================
  // App - Wizard - BankAccounts
  // ==============================
  // 'Welcome to Bore!': 'Welcome to Bore!',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Sign up now, it's free and only takes two minutes to manage your accounts.",
  'Bank details': 'Bank details',
  'Please type your RIB.': 'Please type your RIB.',
  'The RIB must contain 20 digits.': 'The RIB must contain 20 digits.',
  'This bank account is already added to your list.':
    'This bank account is already added to your list.',
  'You can have a maximum of five bank accounts.':
    'You can have a maximum of five bank accounts.',
  'Please verify your RIB.': 'Please verify your RIB.',
  'Please check your bank.': 'Please check your bank.',
  'ex. 20 digits': 'ex. 20 digits',
  'ex. WeCraft': 'ex. WeCraft',
  Currency: 'Currency',
  'Please select a currency.': 'Please select a currency.',
  'Add a bank': 'Add a bank',
  'List of added bank accounts': 'List of added bank accounts',
  // 'Next': 'Next',
  'Error occured.': 'Error occured.',
  // ==============================

  // ==============================
  // App - Wizard - Subscription
  // ==============================
  // 'Welcome to Bore!': 'Welcome to Bore!',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Sign up now, it's free and only takes two minutes to manage your accounts.",
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PricingPlans
  // ==============================
  'Basic plan': 'Basic plan',
  'Business plan': 'Business plan',
  'Enterprise plan': 'Enterprise plan',
  'Per month': 'Per month',
  Start: 'Start',
  Default: 'Default',
  Free: 'Free',
  Trail: 'Trail',
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PaymentForm
  // ==============================
  'Billing Information': 'Billing Information',
  'Firstname *': 'Firstname *',
  Firstname: 'Firstname',
  'Lastname *': 'Lastname *',
  Lastname: 'Lastname',
  'Company Name *': 'Company Name *',
  // 'Company Name': 'Company Name',
  'Country / Region *': 'Country / Region *',
  'Country / Region': 'Country / Region',
  'Postal Code *': 'Postal Code *',
  'Phone *': 'Phone *',
  'Address *': 'Address *',
  Address: 'Address',
  // 'Start': 'Start',
  'Your order': 'Your order',
  VAT: 'VAT',
  Total: 'Total',
  'Pay by credit card with e-dinar card or with your Paymee account.':
    'Pay by credit card with e-dinar card or with your Paymee account.',
  Order: 'Order',
  'Invoice details': 'Invoice details',
  'Your command': 'Your command',
  'Please enter a firstname.': 'Please enter a firstname.',
  'Please enter a lastname.': 'Please enter a lastname.',
  'Please enter a company name.': 'Please enter a company name.',
  'Please enter a country and/or region.':
    'Please enter a country and/or region.',
  'Please enter a valid postal code.': 'Please enter a valid postal code.',
  'Please enter a valid phone number.': 'Please enter a valid phone number.',
  'Please enter an address.': 'Please enter an address.',
  // ==============================

  // ==============================
  // App - Dashboard
  // ==============================
  'Find your company': 'Find your company',
  Light: 'Light',
  Dark: 'Dark',
  Performances: 'Performances',
  Receipts: 'Receipts',
  Transactions: 'Transactions',
  Statements: 'Statements',
  Documents: 'Documents',
  Notifications: 'Notifications',
  Settings: 'Settings',
  'Drag & drop': 'Drag & drop',
  'PNG, JPG, PDF (max. 900 MB)': 'PNG, JPG, PDF (max. 900 MB)',
  'Upload a file': 'Upload a file',
  "Can't upload statement": "Can't upload statement",
  'Statement does not exist': 'Statement does not exist',
  "Image can't be loaded, please check your internet connection":
    "Image can't be loaded, please check your internet connection",
  'Add a new statement': 'Add a new statement',
  'Processing error': 'Processing error',
  'Being analyzed': 'Being analyzed',
  'Something went wrong please contact support for help.':
    'Something went wrong please contact support for help.',
  'Contact support': 'Contact support',
  'Please check the bank statement you uploaded':
    "Please check the bank statement you uploaded, it seems like you uploaded the wrong one or it's missing important information.",
  'Delete statement': 'Delete statement',
  'Return to transactions': 'Return to transactions',
  'Add Manual Transaction': 'Add Manual Transaction',
  ' Channel ': ' Channel ',
  'Credit/Debit': 'Credit/Debit',
  'Transaction type': 'Transaction type',
  Title: 'Title',
  'Payment channel is required': 'Payment channel is required',
  'ShareholderUser is required': 'ShareholderUser is required',
  'Money flow is required': 'Money flow is required',
  'Title is required': 'Title is required',
  'Transaction date is required': 'Transaction date is required',
  'Amount is required': 'Amount is required',
  'Amount must be greater than 0': 'Amount must be greater than 0',
  'Currency is required': 'Currency is required',
  'Add a new transaction': 'Add a new transaction',
  'Successfully Added': 'Successfully Added',
  'The new transaction is now part of the existing list, seamlessly integrated.':
    'The new transaction is now part of the existing list, seamlessly integrated.',
  'Thank you for adding a new transaction':
    'Thank you for adding a new transaction',
  // ==============================
  // App - Dashboard - Export fille
  // ==============================
  Download: 'Download',
  Completed: 'Completed',
  Files: 'Files',
  'Exported data': 'Exported data',
  'Choice date range': 'Choice date range',
  'Account verification': 'Account verification',
  'Enter your password': 'Enter your password',
  'Auth verification': 'Auth verification',
  'Export fille': 'Export file',
  'Export Data Page': 'Export Data Page',
  // ==============================

  // ==============================
  // App - Dashboard - Home
  // ==============================
  'Hello, world!': 'Hello, world!',
  // ==============================

  // ==============================
  // App - Settings
  // ==============================
  'Settings (02)': 'Settings',
  Surname: 'Surname',
  'Phone number': 'Phone number',
  Profile: 'Profile',
  Enterprise: 'Enterprise',
  'Profile updated successfully.': 'Profile updated successfully.',
  'Legal Status': 'Legal Status ',
  'Tax id': 'Tax id ',

  'Password Page': 'Password Page',
  'Old Password': 'Old Password',
  'New Password': 'New Password',
  'Confirm new password': 'Confirm new password',
  'Save changes': 'Save changes',
  Team: 'Team',
  // 'Subscription': 'Subscription',
  'Last Access Date': 'Last Access Date',
  'Invite a user': 'Invite a user',
  'Subscription ends on': 'Subscription ends on',
  MONTH: 'Per month',

  // ==============================
  // *******
  Name: 'Name',

  'Please enter a valid name.': 'Please enter a valid name.',

  // ==============================
  // App - Performance
  // ==============================
  Turnover: 'Turnover',
  Charge: 'Expenses',
  'Operating result': 'Operating result',
  Treasury: 'Treasury',
  'Compared to': 'Compared to',
  'last year': 'last year',

  // ==============================
  // App - Receipts
  // ==============================
  Status: 'Status',
  Amount: 'Amount',
  'Total Amount': 'Total Amount',
  All: 'All',
  'Not associated/analyzed': 'Not associated/analyzed',
  Associated: 'Associated',
  'Not associated': 'Not associated',
  Filter: 'Filter',
  Category: 'Category',
  'Delete filters': 'Delete filters',
  Apply: 'Apply',
  Search: 'Search',
  From: 'Supplier',
  Supplier: 'Supplier',
  'Payment Method': 'Payment method',
  totalAmount: 'Total amount',
  'All Taxes Included': 'All taxes included',
  'Add a VAT line': 'Add a VAT line',
  invoiceDate: 'Invoice date',
  vatAmount: 'Vat amount',
  vatTaxAmount: 'Vat tax amount',
  vatTaxRate: 'Vat tax rate  as %',
  'My receipt': 'My receipt',
  Aucune: 'None',
  Aucun: 'None',
  'Not analyzed': 'Not analyzed',
  'No data table': 'No receipts found',
  Max: 'Max',
  Min: 'Min',
  'Add supporting receipts': 'Add supporting receipts',
  FILE_IS_EMPTY: 'The uploaded file is empty.',
  'Next step': 'Next',
  Upload: 'Upload',
  'Document providing physical proof that something has been done':
    'Document providing physical proof that something has been done',
  'error msg pdf':
    'It seems that the pdf of the receipt is not available, try to upload the document again.',
  'No document available in pdf': 'No document available in pdf',
  'error msg image':
    'It seems that the image of the receipt is not available, try to upload the document again.',
  'No image available': 'No image available',
  Downloads: 'Downloads',
  Analysis: 'Analysis',
  Done: 'Done',
  Uploaded: 'Uploaded',
  UPLOADED: 'Uploaded',
  'Your file is not valid.': 'Your file is not valid.',
  'The receipt is validated': 'The receipt is validated',
  NOT_ASSOCIATED: 'Not associated',
  NOT_ANALYZED: 'Not analyzed',
  ANALYSIS_FAILURE: 'Analysis Failure',
  ANALYZED: 'Analyzed',
  ANALYSIS_SUCCESS: 'Analyzed',
  ANALYSIS_IN_PROGRESS: 'Analysis in progress',
  ASSOCIATED: 'Associated',
  VALIDATED: 'Validated',
  NOT_PAID: 'Not paid',
  PAID: 'Paid',
  'Back to receipts': 'Back to receipts',
  'View in fullscreen': 'View in fullscreen',
  'Sales invoice': 'Sales invoice',
  'Purchase invoice': 'Purchase invoice',
  'Invoice Type': 'Invoice Type',
  TVA: 'VAT',
  'Date not found': 'Date not found',
  "You're about to delete this receipt, are you sure ?":
    "You're about to delete this receipt, are you sure ?",
  'Delete receipt': 'Delete receipt',
  'Delete all receipts': 'Delete all receipts',
  "You're about to delete all the selected receipts, are you sure?":
    "You're about to delete all the selected receipts, are you sure?",
  'date not found': 'Date not found',
  // ==============================
  // App - Transactions
  // ==============================
  Filters: 'Filters',
  Sort: 'Sort',
  by: 'by',
  Date: 'Date',
  'Sale of': 'Sale of',
  Service: 'Service',
  'Operations to validate': 'Operations to validate',
  'Validated operations': 'Validated operations',
  Operations: 'Operations',
  'Change my plan': 'Change my plan',
  'Validate the operation': 'Validate the operation',
  'To categorize': 'To categorize',
  Invalidate: 'Cancel validation',
  Invalidated: 'Not validated',
  'Validate by Owner': 'Validate by Owner',
  'Validate by accoutant': 'Validated by the accountant',
  'The transaction is validated': 'The transaction is validated',
  'The transaction is invalidated': 'transcation validation is cancelled',
  'Error validating transaction': 'Error validating transaction',
  'Account balances': 'Account balances',
  'Software subscription': 'Software subscription',
  'Deposit received': 'Deposit received',
  'VAT paid': 'VAT paid',
  'To choose': 'To choose',
  Perhaps: 'Perhaps?',
  // 'Team': 'Team',
  // 'Subscription': 'Subscription',
  // ==============================

  // ==============================
  // App - Other - Global Error Handler
  // ==============================
  'Server error. Please try again later.':
    'Server error. Please try again later.',
  'Service Unavailable. Please try again later.':
    'Service Unavailable. Please try again later.',
  Unauthorized: 'Unauthorized',
  "You don't have permissions to access this resource.":
    "You don't have permissions to access this resource",
  'Timeout error': 'Timeout error',
  // ==============================

  // ==============================
  // App - Other - Error Boundary
  // ==============================
  'Unexpected Error Occured': 'Unexpected Error Occured',
  // ==============================

  // ==============================
  // App - Other - Subscription Expired
  // ==============================
  'Subscription Expired': 'Subscription Expired',
  'Subscription Expired Description':
    'Your subscription has expired, please click on Renew my subscription to reactivate your account.',
  'Renew My Subscription': 'Renew my subscription',
  'Subscription Expiry Approaching':
    'Your subscription is about to expire, please click on Renew my subscription.',
  'Subscription Days Left': 'Expires in {{days}} days',
  'Expires in less than 1 day': 'Expires in less than 1 day',
  // ==============================

  // ==============================
  // App - Other - Not Found
  // ==============================
  'Page Not Found': 'Page Not Found',
  // ==============================

  // App - Handle errors
  // ==============================
  AUTH_SIGNIN_BAD_CREDENTIALS: 'The email or password is incorrect',
  AUTH_SIGNIN_USER_LOCKED: 'This account is currently locked',
  AUTH_SIGNIN_USER_NOT_ACTIVATED: 'This account is not activated',
  AUTH_SIGNIN_INVALID_RECAPTCHA_TOKEN:
    'The captcha token is invalid or expired',

  AUTH_SIGNUP_EMAIL_ALREADY_EXISTS:
    'This email is already used in another account',
  INTERNAL_ERROR: 'An internal server error has occurred',
  COMPANY_COMPANY_EXIST: 'Company already exist',
  COMPANY_ID_NOT_FOUND: 'User not found',
  COMPANY_INVALID_CREATE_COMPANY_REQUEST:
    'Something went wrong, please recheck your company information.',
  SETTINGS_ID_MEMBER_NOT_FOUND: 'The member is not found.',
  SETTINGS_INVITATION_EXISTS:
    'An invitation has already been sent to the provided email',
  SETTINGS_MEMBER_EXISTS: 'A member with the provided email already exists',
  SETTINGS_COMPANY_NOT_FOUND: 'The company is not found.',
  SETTINGS_ID_NOT_FOUND: 'The user id does not belong to any account',
  UNAUTHORIZED_UNAUTHORIZED: 'Unauthorized access',
  'Oops! Page not found': 'Oops!\n Page not found',
  "This page doesn't exist or get removed. We suggest you back to home":
    "This page doesn't exist or get removed. We suggest you go back home.",
  'Go back home': 'Go back home',
  'Access denied': 'Oops!\n Access denied',
  "You don't have permission to access requested page.":
    "You don't have permission to access requested page. We suggest you go back home.",
  'Oh no!': 'Oh no!',
  'So sorry, something not working well please try again later or feel free to contact support.':
    'So sorry, something not working well please try again later or feel free to contact support.',
  'Something went wrong': 'Something went wrong',
  // ==============================
  // Month
  // ==============================
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
};

export default EN_TRANSLATIONS;

import type { FC } from 'react';

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useNavigate } from 'react-router-dom';

interface NotificationProps {}

const Notification: FC<NotificationProps> = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/dashboard/notifications')}
      className="bg-sec p-3 rounded-full relative cursor-pointer "
    >
      {/* //! Keep it just in case */}
      {/* <div className="bg-[#45C49C] color-button-text absolute pl-1 pr-1 top-[-2px] right-[-4px] f-14px flex justify-center items-center rounded-full">
        <h4>12</h4>
      </div> */}
      <NotificationsOutlinedIcon htmlColor="#8D98AF" />
    </button>
  );
};

export default Notification;

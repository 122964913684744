import type { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { error } from '@/assets/images';
import { Button } from '@/components/global';

interface ErrorBoundaryFallbackProps {}

const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-center flex-col flex-row-reverse items-center gap-16 h-screen w-full sm:flex-row max-w-[1400px] mx-auto">
        <div className="w-full sm:w-3/5">
          <img className="mx-auto" src={error} alt="Error" />
        </div>

        <div className="w-full px-10 sm:px-0 sm:w-2/5">
          <h1 className="font-bold text-8xl	mb-1">{t('Oh no!')}</h1>

          <div className="flex flex-col gap-4 sm:gap-10">
            <h3 className="whitespace-pre-line text-xl">
              {t('Something went wrong')}
            </h3>
            <p className="text-gray-500	text-sm sm:text-base">
              {t(
                'So sorry, something not working well please try again later or feel free to contact support.'
              )}
            </p>
          </div>

          <div className="flex gap-4 flex-wrap sm:flex-nowrap">
            <Button
              onClick={() => {
                navigate('/dashboard');
                window.location.reload();
              }}
              cls={'mt-6 w-full sm:w-1/2'}
              label={t('Go back home')}
            />

            <Button
              onClick={() => navigate('/dashboard')}
              cls=" bg-transparent mt-6 w-full sm:w-1/2 text-[#1B262C] border-[#1B262C] border-2 hover:bg-[#1B262C] hover:text-white"
              label={t('Contact support')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;

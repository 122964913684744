import moment from 'moment';

import type { ManualTransaction } from '@/lib/forms/transactions-forms';
import type { StatusEnum } from '@/pages/dashboard/Receipts/enums';
import operationsAPI from '@/services/apis/operationsAPI';

import type { Filters, Receipt } from './models';

export default class OperationsService {
  static async getTransactions(
    filters: {
      status: string;
      currency: string;
      amount: number[];
      bank_account_id: string;
      pageSize: number;
      pageNumber: number;
    },
    signal?: AbortSignal
  ) {
    const queryParameters: string[] = [];
    if (filters) {
      if (filters.status) {
        queryParameters.push(`status=${filters.status}`);
      }
      if (filters.currency) {
        queryParameters.push(`currencyId=${filters.currency}`);
      }
      if (filters.amount) {
        queryParameters.push(`minAmount=${filters.amount[0]}`);
        queryParameters.push(`maxAmount=${filters.amount[1]}`);
      }
      if (filters.bank_account_id) {
        queryParameters.push(`bank_account_id=${filters.bank_account_id}`);
      }
      if (filters.pageSize) {
        queryParameters.push(`page_size=${filters.pageSize}`);
      }
      if (filters.pageNumber) {
        queryParameters.push(`page_number=${filters.pageNumber}`);
      }
    }

    return operationsAPI.get(`/v1/transactions?${queryParameters.join('&')}`, {
      signal,
    });
  }

  static async createTransaction(
    data: ManualTransaction,
    signal?: AbortSignal
  ) {
    const formData = new FormData();
    formData.append('paymentChannel', data.paymentChannel);
    formData.append('shareholderUserId', data.shareholderUserId);
    formData.append('flowDirection', data.flowDirection);
    formData.append('annotation', data.description);
    formData.append(
      'transactionDate',
      moment(data.transactionDate).format('YYYY/MM/DD')
    );
    formData.append('description', data.annotation);
    formData.append('amount', String(data.amount));
    formData.append('currencyId', data.currencyId);
    return operationsAPI.post(`/v1/transactions`, formData, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async validateTransactionByOwner(id: string, signal?: AbortSignal) {
    return operationsAPI.patch(`/v1/transactions/${id}/validate/owner`, {
      signal,
    });
  }
  static async validateTransactionByAccountant(
    id: string,
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`/v1/transactions/${id}/validate/accountant`, {
      signal,
    });
  }
  static async invalidateTransaction(id: string, signal?: AbortSignal) {
    return operationsAPI.patch(`/v1/transactions/${id}/invalidate`, {
      signal,
    });
  }

  static async getReceiptsWithPagination(
    pageNumber: number | undefined,
    pageSize: number | undefined,
    statusFilter: StatusEnum | null,
    filters: Filters | null,
    signal?: AbortSignal
  ) {
    let url = '/v1/receipts';
    const queryParameters: string[] = [];
    if (pageNumber) {
      queryParameters.push(`pageNumber=${pageNumber}`);
    }
    if (pageSize) {
      queryParameters.push(`pageSize=${pageSize}`);
    }
    if (statusFilter) {
      queryParameters.push(`status=${statusFilter}`);
    }
    if (filters) {
      if (filters.ids) {
        queryParameters.push(`ids=${filters.ids}`);
      }
      if (filters.category) {
        queryParameters.push(`category=${filters.category}`);
      }
      if (filters.totalAmount) {
        queryParameters.push(`minAmount=${filters.totalAmount[0]}`);
        queryParameters.push(`maxtotalAmount=${filters.totalAmount[1]}`);
      }
      if (filters.vatMin) {
        queryParameters.push(`vatMin=${Number(filters.vatMin)}`);
      }
      if (filters.vatMax) {
        queryParameters.push(`vatMax=${Number(filters.vatMax)}`);
      }
      if (filters.searchQuery) {
        queryParameters.push(`searchQuery=${filters.searchQuery}`);
      }
    }
    if (queryParameters.length > 0) {
      url += '?' + queryParameters.join('&');
    }
    return operationsAPI.get(url, {
      signal,
    });
  }
  static async createReceipt(data: FormData, signal?: AbortSignal) {
    return operationsAPI.post(`/v1/receipts`, data, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  static async updateReceipt(data: Receipt, signal?: AbortSignal) {
    return operationsAPI.put(`/v1/receipts`, data, {
      signal,
    });
  }

  static getReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.get(`v1/receipts/${id}`, {
      signal,
    });
  }

  static updateTransaction(
    id: string,
    data: {
      categoryCode?: string;
      ventilations?: any;
      receiptsIds?: Array<string>;
    },
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`v1/transactions/${id}`, data, {
      signal,
    });
  }

  static assignReceipts(
    id: string,
    data: { receiptsIds?: Array<string> },
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`v1/transactions/${id}/assign`, data, {
      signal,
    });
  }

  static unassignReceipts(
    id: string,
    data: { receiptsIds?: Array<string> },
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`v1/transactions/${id}/unassign`, data, {
      signal,
    });
  }

  static exportData(signal?: AbortSignal) {
    return operationsAPI.get('v1/book-entries', {
      signal,
      responseType: 'blob',
    });
  }

  static getKpiValidation(signal?: AbortSignal) {
    return operationsAPI.get(`v1/kpis/kpi-validation`, {
      signal,
    });
  }
  static deleteReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.delete(`/v1/receipts/${id}`, {
      signal,
    });
  }

  static deleteReceiptsByIds(ids: string[], signal?: AbortSignal) {
    return operationsAPI.delete(`/v1/receipts/multiple-delete?ids=${ids}`, {
      signal,
    });
  }
}

import type { FC } from 'react';

import { Outlet } from 'react-router-dom';

interface AuthProps {}

const Auth: FC<AuthProps> = () => {
  return <Outlet />;
};

export default Auth;

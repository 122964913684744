import * as yup from 'yup';

import type {
  InvoiceType,
  PaymentMethod,
} from '@/pages/dashboard/Receipts/enums';
import type { Vat } from '@/services';

export type ReceiptFileFormType = {
  file: File[];
};

export const ReceiptFileFormSchema = yup.object().shape({
  file: yup.mixed(),
});
export const ReceiptFormUpdateSchema = yup.object().shape({});

export type ReceiptFormUpdate = {
  supplierName: string;
  invoiceDate: Date | string;
  invoiceType: InvoiceType | null;
  totalAmount: string | number | null;
  paymentMethod: PaymentMethod | null;
  vatList?: Vat[] | null;
  categoryCode: string | null;
  currency: string | null;
};

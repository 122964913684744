import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { DashboardLayout } from '@/components/layouts';
// import { AuthGuard, RolesGuard } from '@/lib/guards';
// import SubscriptionGuard from '@/lib/guards/SubscriptionGuard';
import ReceiptPage from '@/pages/dashboard/Receipts/Receipt/Receipt';

import type { RouteType } from '.';

// import { Role } from '../lib/guards/RolesGuard';

const Test = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Test')), 500);
  });
});

const Documents = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Documents')), 500);
  });
});

const Notifications = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Notifications')), 500);
  });
});

const Performances = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Performances')), 500);
  });
});

const NoPerformances = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Performances/NoPerformances')),
      500
    );
  });
});

const Receipts = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Receipts')), 500);
  });
});

const UploadReceipts = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Receipts/UploadReceipts')),
      500
    );
  });
});

const Statements = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Statements/')), 500);
  });
});

const UploadStatements = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Statements/UploadStatements')),
      500
    );
  });
});

const AddBankAccounts = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Statements/AddBankAccounts')),
      500
    );
  });
});

const Transactions = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Transactions')), 500);
  });
});

const NoTransactions = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Transactions/NoTransactions')),
      500
    );
  });
});

const CreateTransactionManual = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Transactions/TransactionManual')),
      500
    );
  });
});

const ExportData = lazy(() => {
  const exportDataPath = import('@/pages/dashboard/Accounting/ExportData');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(exportDataPath), 500);
  });
});

const AccountingPlan = lazy(() => {
  const AccountingPlanPath = import(
    '@/pages/dashboard/Accounting/AccountingPlan'
  );
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AccountingPlanPath), 500);
  });
});

const outletElements = [
  {
    href: '',
    element: <Navigate to="performances" />,
  },
  {
    href: 'test',
    element: <Test />,
  },
  {
    href: 'documents',
    element: <Documents />,
  },
  {
    href: 'notifications',
    element: <Notifications />,
  },
  {
    href: 'performances',
    element: <Performances />,
  },
  {
    href: 'performances/no-data',
    element: <NoPerformances />,
  },
  {
    href: 'receipts',
    element: <Receipts />,
  },
  {
    href: 'receipts/upload',
    element: <UploadReceipts />,
  },
  {
    href: 'receipts/:id',
    element: <ReceiptPage />,
  },
  {
    href: 'statements',
    element: <Statements />,
  },
  {
    href: 'statements/upload',
    element: <UploadStatements />,
  },
  {
    href: 'statements/add-bank-accounts',
    element: <AddBankAccounts />,
  },
  {
    href: 'transactions',
    element: <Transactions />,
  },
  {
    href: 'transactions/create-transaction-manual',
    element: <CreateTransactionManual />,
  },

  {
    href: 'transactions/no-data',
    element: <NoTransactions />,
  },
  {
    href: 'accounting/export-data',
    element: <ExportData />,
  },
  {
    href: 'accounting/accounting-plan',
    element: <AccountingPlan />,
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      // <AuthGuard>
      // <RolesGuard allowedRoles={[Role.OWNER, Role.USER]}>
      // <SubscriptionGuard>
      <DashboardLayout />
      // </SubscriptionGuard>
      // </RolesGuard>
      // </AuthGuard>
    ),
    path: 'dashboard',
  },
];

export default routes;

import type { FC, ReactNode } from 'react';

import { Close } from '@mui/icons-material';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ToastType = 'success' | 'error' | 'warn' | 'info';

export enum ToastContainerId {
  APP,
  DASHBOARD,
}

const contextClass = {
  success: 'text-[#2B641E] bg-[#EDFBD8]',
  error: 'text-[#71192F] bg-[#FCE8DB]',
  info: 'text-[#0C2A75] bg-[#D7F1FD]',
  warning: 'text-[#755118] bg-[#FEF7D1]',
  default: 'bg-indigo-600',
};

const getToast =
  (type: ToastType, containerId: ToastContainerId) =>
  (title?: ReactNode, description?: ReactNode) => {
    return (
      title &&
      toast[type](
        <div>
          {title && (
            <p className="font-switzer text-[18xp] font-bold">{title}</p>
          )}
          {description && (
            <p className="text-[16xp] font-normal">{description}</p>
          )}
        </div>,
        { containerId }
      )
    );
  };

const getAppToast = (type: ToastType) => getToast(type, ToastContainerId.APP);

const getDashToast = (type: ToastType) =>
  getToast(type, ToastContainerId.DASHBOARD);

export const appToast = {
  success: getAppToast('success'),
  error: getAppToast('error'),
  warn: getAppToast('warn'),
  info: getAppToast('info'),
};

export const dashToast = {
  success: getDashToast('success'),
  error: getDashToast('error'),
  warn: getDashToast('warn'),
  info: getDashToast('info'),
};

interface ToastContainersProps {}

const ToastContainers: FC<ToastContainersProps> = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        style={{ lineHeight: '1.4' }}
        enableMultiContainer
        containerId={ToastContainerId.APP}
      />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        style={{ lineHeight: '1.4' }}
        enableMultiContainer
        containerId={ToastContainerId.DASHBOARD}
        toastClassName={({ type }: any) =>
          (contextClass as any)[type || 'default'] +
          ' relative flex p-1 min-h-10 mb-2 rounded-lg justify-between overflow-hidden cursor-pointer top-[75px]'
        }
        bodyClassName={() => 'relative font-white font-med flex gap-1 p-3'}
        closeButton={<Close className="-translate-x-[8px] translate-y-[8px]" />}
      />
    </>
  );
};

export default ToastContainers;

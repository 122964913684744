import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { SettingsLayout } from '@/components/layouts';
import { AuthGuard, SubscriptionGuard } from '@/lib/guards';

import type { RouteType } from '.';

const Enterprise = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error

    setTimeout(() => resolve(import('@/pages/settings/Enterprise')), 500);
  });
});

const Profile = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Profile')), 500);
  });
});

const Subscription = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Subscription')), 500);
  });
});

const Team = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Team')), 500);
  });
});

const Password = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Password')), 500);
  });
});

const outletElements = [
  {
    href: '',
    element: <Navigate to="profile" />,
  },
  {
    href: 'profile',
    element: <Profile />,
  },
  {
    href: 'enterprise',
    element: <Enterprise />,
  },
  {
    href: 'team',
    element: <Team />,
  },
  {
    href: 'subscription',
    element: <Subscription />,
  },
  {
    href: 'password',
    element: <Password />,
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <AuthGuard>
        <SubscriptionGuard>
          <SettingsLayout />
        </SubscriptionGuard>
      </AuthGuard>
    ),
    path: 'settings',
  },
];

export default routes;

import { lazy } from 'react';

import { Route } from 'react-router-dom';

import { JarLayout } from '@/components/layouts';

import type { RouteType } from '.';

const Redirection = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/jar/Redirection')), 500);
  });
});

const outletElements = [
  //   {
  //     element: <Navigate to="sign-in" />,
  //     href: '',
  //   },
  {
    element: <Redirection />,
    href: ':jarId',
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: <JarLayout />,
    path: 'jar',
  },
];

export default routes;

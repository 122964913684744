import { SubscriptionExpired } from '@/components/global';

const SubscriptionExpiredMessageWrapper = () => {
  return (
    <div className="w-[100%]">
      <SubscriptionExpired />
    </div>
  );
};

export default SubscriptionExpiredMessageWrapper;

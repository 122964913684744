import { useMutation } from '@tanstack/react-query';

import type { Receipt } from '@/services';
import { OperationsService } from '@/services';

export const useCreateReceipt = () =>
  useMutation({
    mutationFn: (data: FormData) => {
      return OperationsService.createReceipt(data);
    },
  });

export const useUpdateReceipt = () =>
  useMutation({
    mutationFn: (data: Receipt) => {
      return OperationsService.updateReceipt(data);
    },
  });

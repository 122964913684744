/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Delete,
  KeyboardBackspaceRounded,
  NorthRounded,
  SouthRounded,
  ZoomOutMapRounded,
} from '@mui/icons-material';
import { Pagination, Tooltip } from '@mui/material';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { Modal, ModalGateway } from 'react-images';
import { RotatingLines } from 'react-loader-spinner';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, RSelect } from '@/components/global';
import { useAppDispatch, useAppTranslation } from '@/hooks';
import { useUpdateReceipt } from '@/hooks/receipts-hooks';
import { appToast } from '@/lib/ToastContainers';
import type { ReceiptFormUpdate } from '@/lib/forms/receipts-forms';
import { ReceiptFormUpdateSchema } from '@/lib/forms/receipts-forms';
import { ObjectPdf } from '@/pages/dashboard/common';
import type { Currency, Receipt, Vat } from '@/services';
import {
  AccountingService,
  OperationsService,
  SettingsService,
} from '@/services';
import {
  setKpiValidationReceipt,
  setKpiValidationTransaction,
} from '@/store/actions/action-creators';
import { cls } from '@/utils/funcs';

import type { Category } from '../../Accounting/AccountingPlan/AccountingPlan';
import { InvoiceType, PaymentMethod, StatusEnum } from '../enums';
import { ImageReceipt } from './index';

interface ReceiptProps {}

const ReceiptPage: FC<ReceiptProps> = () => {
  const { id } = useParams();
  const tableRef = useRef<HTMLTableElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [receipt, setReceipt] = useState<Receipt>();
  const [receiptId, setReceiptId] = useState(id);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      supplierName: receipt?.supplierName || '',
      totalAmount: receipt?.totalAmount || null,
      invoiceDate: receipt?.invoiceDate || '',
      invoiceType: receipt?.invoiceType || null,
      paymentMethod: receipt?.paymentMethod || null,
      categoryCode: receipt?.categoryCode || null,
      currency: receipt?.currency || null,
    },
    mode: 'all',
    resolver: yupResolver(ReceiptFormUpdateSchema),
  });

  const { mutate: updateReceiptMutate } = useUpdateReceipt();
  const [pageNumber, setPageNumber] = useState(1);
  const [categoriesList, setCategoriesList] = useState<Category[]>();
  const [pageSize] = useState(10);
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [selectedReceiptIndex, setSelectedReceiptIndex] = useState(0);
  const [isRotatingLines, setIsRotatingLines] = useState(false);
  const [totalPages, setTotalPages] = useState(2);
  const { t } = useAppTranslation();
  const columns = [t('Receipt'), t('Status')];

  const toggleModal = () =>
    setIsModalOpen((isModalOpen: boolean) => !isModalOpen);

  const [formValues, setFormValues] = useState<ReceiptFormUpdate>({
    supplierName: '',
    invoiceDate: '',
    invoiceType: null,
    totalAmount: null,
    paymentMethod: null,
    vatList: null,
    categoryCode: null,
    currency: null,
  });

  const onSubmit: SubmitHandler<ReceiptFormUpdate> = (data) => {
    const updatedReceipt: Receipt = {
      ...receipt,
      vatList: vatLines || [], // Use the updated vatList values from the form
      totalAmount: formValues?.totalAmount as number,
      invoiceDate: new Date(formValues?.invoiceDate as string),
      supplierName: formValues?.supplierName,
      invoiceType: data.invoiceType as InvoiceType,
      paymentMethod: data.paymentMethod as PaymentMethod,
      categoryCode: data.categoryCode as string,
      currency: data.currency as string,
      companyId: receipt?.companyId,
      id: receipt?.id,
    };

    setReceipt({ ...updatedReceipt });

    updateReceiptMutate(updatedReceipt, {
      onSuccess: () => {
        appToast.success(t('The receipt is validated'));
        OperationsService.getKpiValidation().then((res) => {
          dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
          dispatch(
            setKpiValidationTransaction(res.data?.validatedTransactionNumber)
          );
        });
      },
    });
  };

  const setPageNumberFunction = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  useEffect(() => {
    setIsRotatingLines(true);

    OperationsService.getReceiptsWithPagination(
      pageNumber,
      pageSize,
      StatusEnum.ALL,
      null
    ).then((receipt) => {
      setReceipts(receipt.data?.receipts);
      setTotalPages(Math.ceil(receipt.data.totalNumberOfPages));
      setIsRotatingLines(false);
    });
  }, [pageNumber, pageSize]);

  useEffect(() => {
    const controller = new AbortController();

    if (receiptId) {
      OperationsService.getReceipt(receiptId, controller.signal).then((res) => {
        setReceipt(res.data);
        setFormValues(res.data);
        setVatLines(res?.data?.vatList);

        AccountingService.getCategories(
          {
            code: res.data.invoiceType === 'PURCHASE' ? 401 : 41,
          },
          controller.signal
        ).then(({ data }) => {
          setCategoriesList(data.categories);
        });
      });
    }
    return () => controller.abort();
  }, [receiptId]);

  useEffect(() => {
    setValue('invoiceType', formValues.invoiceType);
    setValue('paymentMethod', formValues.paymentMethod);
    setValue('categoryCode', formValues.categoryCode);
    setValue('currency', formValues.currency);
  }, [formValues]);

  const getFileExtension = (filename: string | undefined): string => {
    const extension = filename?.split('.').pop()?.toLowerCase();
    return extension || '';
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.focus();
    }

    // Add an event listener for keydown on the window
    const handleKeyDown = (e: KeyboardEvent) => {
      if (tableRef.current && e.target !== tableRef.current) {
        tableRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const controller = new AbortController();

    Promise.all([SettingsService.getCurrencies(controller.signal)]).then(
      ([currenciesResponse]) => {
        setCurrencies(currenciesResponse.data);
      }
    );
    return () => {
      controller.abort();
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [vatLines, setVatLines] = useState<Vat[]>([]);

  const addVatLine = () => {
    const newVatLine: Vat = {
      vatAmount: 0,
      vatTaxAmount: 0,
      vatTaxRate: 0,
    };

    setVatLines((prevVatLines) => {
      if (prevVatLines && typeof prevVatLines[Symbol.iterator] === 'function') {
        return [...prevVatLines, newVatLine];
      } else {
        return [newVatLine];
      }
    });
  };

  const deleteVatLine = (index: number) => {
    setVatLines((prevVatLines) => {
      const updatedVatLines = [...prevVatLines];
      updatedVatLines.splice(index, 1);
      return updatedVatLines;
    });
  };

  const handleChange = (
    element: keyof Vat,
    index: number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value; // Get the input value without trimming
    const parsedValue = value !== '' ? parseFloat(value) : null;

    const updatedVatLines = [...vatLines];
    updatedVatLines[index][element] = parsedValue;
    setVatLines(updatedVatLines);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let processedValue: string | number | null = value;

    if (name === 'supplierName') {
      setFormValues((prevValues) => ({
        ...prevValues,
        supplierName: value,
      }));
    } else if (name === 'invoiceDate') {
      setFormValues((prevValues) => ({
        ...prevValues,
        invoiceDate: value,
      }));
    } else if (name === 'totalAmount') {
      // If the processed value is empty, set it to null
      processedValue = value !== '' ? parseFloat(value) : null;

      setFormValues((prevValues) => ({
        ...prevValues,
        totalAmount: processedValue,
      }));
    }
  };

  function getStatusClass(fileStatus: any) {
    if (fileStatus === StatusEnum.VALIDATED) {
      return 'text-[#14A44D]';
    } else if (
      fileStatus === StatusEnum.ANALYSIS_SUCCESS ||
      fileStatus === StatusEnum.ANALYSIS_IN_PROGRESS
    ) {
      return 'text-[#E4A11B]';
    } else {
      return 'text-[#DC4C64]';
    }
  }

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        handleArrowUp();
        break;
      case 'ArrowDown':
        e.preventDefault();
        handleArrowDown();
        break;

      default:
        break;
    }
  };

  const handleArrowUp = () => {
    if (selectedReceiptIndex > 0) {
      setSelectedReceiptIndex((prevIndex) => prevIndex - 1);
      setReceiptIdFromIndex(selectedReceiptIndex - 1);
    }
  };

  const handleArrowDown = () => {
    if (selectedReceiptIndex < receipts.length - 1) {
      setSelectedReceiptIndex((prevIndex) => prevIndex + 1);
      setReceiptIdFromIndex(selectedReceiptIndex + 1);
    }
  };

  const setReceiptIdFromIndex = (index: number) => {
    if (receipts[index]) {
      setReceiptId(receipts[index].id);
    }
  };

  return (
    // <section className="mt-[81px] p-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] overflow-y-scroll">
    <section className="mt-[81px] p-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] overflow-y-scroll">
      {/* HEADER */}
      <div className="mb-2">
        <Button
          icon={<KeyboardBackspaceRounded className="!h-6 !w-6 mr-1" />}
          label={t('Back to receipts')}
          cls="border-[2px] bg-transparent text-[#1B262C] border-[#1B262C] hover:opacity-80 transition-opacity duration-200"
          onClick={() => navigate(`/dashboard/receipts`)}
        />
      </div>

      <div className="flex gap-4 max-h-[calc(73vh)]">
        <div className="flex-[1.5] rounded-3xl bg-white px-6 py-8 max-w-sm  overflow-hidden">
          <div className="flex items-center justify-between mb-4">
            <p className="text-[#171725] font-semibold text-xl  whitespace-nowrap overflow-ellipsis">
              {t('My receipt')}
            </p>

            <Tooltip title="You can navigate using the arrow buttons">
              <div className="flex gap-1">
                <div className="border-2 border-solid border-[#7B7B8D] p-1 rounded-lg flex justify-center items-center h-fit">
                  <NorthRounded
                    style={{ height: 15, width: 15 }}
                    htmlColor="#7B7B8D"
                  />
                </div>
                <div className="border-2 border-solid border-[#7B7B8D] p-1 rounded-lg flex justify-center items-center h-fit">
                  <SouthRounded
                    style={{ height: 15, width: 15 }}
                    htmlColor="#7B7B8D"
                  />
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="overflow-y-scroll max-h-full">
            {!isRotatingLines ? (
              <>
                <table
                  className="w-full text-left text-gray-500 focus:outline-none"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                  ref={tableRef}
                >
                  <thead className="text-gray-700 border-b">
                    <tr>
                      {columns.map((column, idx) => (
                        <th
                          scope="col"
                          className="px-3 py-4 text-xs font-bold text-[#8D98AF] bg-[#F8FAFB]"
                          key={idx}
                        >
                          {column}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {receipts.map(({ fileName, id, fileStatus }, idx) => (
                    <tr
                      style={{
                        backgroundColor: receiptId === id ? '#F2F2F2' : 'white',
                      }}
                      className={`${
                        receiptId === id ? 'bg-[#F2F2F2]' : 'bg-white'
                      } bg-white border-b text-xs cursor-pointer hover:bg-[#F2F2F2] transition-colors dark:hover:bg-[#1b1d2c] duration-200`}
                      key={idx}
                      onClick={() => (
                        setReceiptId(id), navigate(`/dashboard/receipts/${id}`)
                      )}
                    >
                      <td className="px-3 py-4">{fileName}</td>
                      <td
                        className={cls('px-3 py-4', getStatusClass(fileStatus))}
                      >
                        {t(`${fileStatus}`)}
                      </td>
                    </tr>
                  ))}
                  <div className="py-6 w-full flex justify-end">
                    <Pagination
                      page={pageNumber}
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      onChange={setPageNumberFunction}
                      color="primary"
                      size="large"
                    />
                  </div>
                </table>
              </>
            ) : (
              <div className="flex flex-row justify-center mt-52">
                <RotatingLines visible={true} strokeColor="grey" width="30" />
              </div>
            )}
          </div>
        </div>

        <div className="flex-[1.4] rounded-3xl bg-white px-2 py-4 max-h-[calc(80vh)] scroll-mb-16 ">
          <div
            className="text-[#7B7B8D] font-semibold text-lg flex gap-2 justify-center items-center mb-5 cursor-pointer hover:opacity-80 transition-opacity duration-300"
            onClick={toggleModal}
          >
            <ZoomOutMapRounded />
            <p>{t('View in fullscreen')}</p>
          </div>

          {/* @ts-expect-error */}
          <ModalGateway>
            {isModalOpen ? (
              <Modal onClose={toggleModal} allowFullscreen={false}>
                <Carousel>
                  {/*  */}
                  <></>
                  <div>
                    {receipt?.fileUrl &&
                    getFileExtension(receipt?.fileName) === 'pdf' ? (
                      <ObjectPdf
                        clsContainer="flex flex-col"
                        url={receipt?.fileUrl ? receipt?.fileUrl : ''}
                        cls={'w-[150vh] h-[90vh]'}
                      />
                    ) : (
                      <ImageReceipt
                        fullscreen={true}
                        url={receipt?.fileUrl ? receipt?.fileUrl : ''}
                        alt="receipt"
                        cls="w-[65vh] h-[80vh]"
                      />
                    )}
                  </div>
                </Carousel>
              </Modal>
            ) : null}
          </ModalGateway>

          <div className="rounded-xl bg-[#F4F4F4] w-full overflow-hidden">
            {receipt?.fileUrl &&
            getFileExtension(receipt?.fileName) === 'pdf' ? (
              <ObjectPdf
                clsContainer="flex flex-col w-full"
                url={receipt?.fileUrl ? receipt?.fileUrl : ''}
                cls={'w-full h-[60vh]'}
              />
            ) : (
              <ImageReceipt
                url={receipt?.fileUrl ? receipt?.fileUrl : ''}
                alt="receipt"
                cls={'max-h-[calc(55vh)]'}
              />
            )}
          </div>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-[1.75] rounded-3xl bg-white px-6 py-8 flex flex-col gap-5  overflow-y-auto relative w-[480px] max-h-[calc(73vh)]">
              <Input
                refs={register('supplierName')}
                name="supplierName"
                label={t('Fournisseur')}
                type="text"
                value={formValues.supplierName || ''}
                labelCls="text-[#7B7B8D] text-md"
                errorMsg={errors.supplierName && errors.supplierName.message}
                onChange={handleInputChange}
              />

              <Input
                refs={register('invoiceDate')}
                name="invoiceDate"
                label={t('invoiceDate')}
                type="date"
                value={moment(formValues.invoiceDate).format('YYYY-MM-DD')}
                labelCls="text-[#7B7B8D] text-md"
                onChange={handleInputChange}
                errorMsg={errors.invoiceDate && errors.invoiceDate.message}
              />
              <RSelect
                id="invoiceType"
                label={t('Invoice Type')}
                labelCls="text-[#7B7B8D] text-md"
                options={[
                  { label: t('Purchase invoice'), value: InvoiceType.PURCHASE },
                  { label: t('Sales invoice'), value: InvoiceType.SALE },
                ]}
                value={formValues.invoiceType}
                control={control}
                refs={register('invoiceType')}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '48px',
                    borderRadius: '10px',
                    border: '2px solid rgb(210, 209, 214) !important',
                  }),
                  option: (provided, state: any) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? '#e5e7eb'
                      : 'transparent',
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                    '&:hover': {
                      backgroundColor: '#e5e7eb',
                    },
                  }),
                  singleValue: (provided, state: any) => ({
                    ...provided,
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                    backgroundColor: '#fff',
                  }),
                }}
              />
              <RSelect
                id="categoryCode"
                label={t(
                  formValues.invoiceType === 'PURCHASE' ? 'Suppliers' : 'Client'
                )}
                labelCls="text-[#7B7B8D] text-md"
                options={categoriesList?.map((el) => {
                  return { label: `${el.code} - ${el.label}`, value: el.code };
                })}
                value={formValues.categoryCode}
                control={control}
                refs={register('categoryCode')}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '48px',
                    borderRadius: '10px',
                    border: '2px solid rgb(210, 209, 214) !important',
                  }),
                  option: (provided, state: any) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? '#e5e7eb'
                      : 'transparent',
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                    '&:hover': {
                      backgroundColor: '#e5e7eb',
                    },
                  }),
                  singleValue: (provided, state: any) => ({
                    ...provided,
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                    backgroundColor: '#fff',
                  }),
                }}
              />
              <div className="flex space-x-4">
                <Input
                  name="totalAmount"
                  label={t('totalAmount')}
                  type="number"
                  value={formValues.totalAmount ?? ''}
                  labelCls="text-[#7B7B8D] text-md"
                  onChange={handleInputChange}
                  errorMsg={errors.totalAmount && errors.totalAmount.message}
                />
                <RSelect
                  id="currency"
                  label={t('Currency')}
                  labelCls="text-[#7B7B8D] text-md"
                  options={currencies.map((currency) => ({
                    value: currency.code,
                    label: currency.code,
                  }))}
                  value={formValues.currency}
                  control={control}
                  refs={register('currency')}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '48px',
                      borderRadius: '10px',
                      border: '2px solid rgb(210, 209, 214) !important',
                      width: '13vh',
                    }),
                    option: (provided, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused
                        ? '#e5e7eb'
                        : 'transparent',
                      color: state.data.value === null ? '#7B7B8D' : '#111827',
                      '&:hover': {
                        backgroundColor: '#e5e7eb',
                      },
                    }),
                    singleValue: (provided, state: any) => ({
                      ...provided,
                      color: state.data.value === null ? '#7B7B8D' : '#111827',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                      boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                      backgroundColor: '#fff',
                    }),
                  }}
                />
              </div>
              <RSelect
                label={t('Payment Method')}
                labelCls="text-[#7B7B8D] text-md"
                options={[
                  { label: 'Bank Card', value: PaymentMethod.BANK_CARD },
                  { label: t('Aucun'), value: null },
                ]}
                control={control}
                refs={register('paymentMethod')}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '48px',
                    borderRadius: '10px',
                    border: '2px solid rgb(210, 209, 214) !important',
                  }),
                  option: (provided, state: any) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? '#e5e7eb'
                      : 'transparent',
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                    '&:hover': {
                      backgroundColor: '#e5e7eb',
                    },
                  }),
                  singleValue: (provided, state: any) => ({
                    ...provided,
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                    backgroundColor: '#fff',
                  }),
                }}
              />

              <Input
                name="totalAmount"
                label={`${t('totalAmount')} (${t('All Taxes Included')}) ${
                  receipt?.currency ? receipt?.currency : 'DT'
                }`}
                type="number"
                value={formValues.totalAmount ?? ''}
                labelCls="text-[#7B7B8D] text-md"
                onChange={handleInputChange}
                errorMsg={errors.totalAmount && errors.totalAmount.message}
              />

              <RSelect
                label={t('Payment Method')}
                labelCls="text-[#7B7B8D] text-md"
                options={[
                  { label: 'Bank Card', value: PaymentMethod.BANK_CARD },
                  { label: t('Aucun'), value: null },
                ]}
                control={control}
                refs={register('paymentMethod')}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '48px',
                    borderRadius: '10px',
                    border: '2px solid rgb(210, 209, 214) !important',
                  }),
                  option: (provided, state: any) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? '#e5e7eb'
                      : 'transparent',
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                    '&:hover': {
                      backgroundColor: '#e5e7eb',
                    },
                  }),
                  singleValue: (provided, state: any) => ({
                    ...provided,
                    color: state.data.value === null ? '#7B7B8D' : '#111827',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                    backgroundColor: '#fff',
                  }),
                }}
              />

              <div>
                <div className="flex items-center justify-between mt-3 mb-5">
                  <p className="font-semibold text-xl text-[#7B7B8D]">
                    {t('TVA')} : {receipt?.totalTaxAmount} {receipt?.currency}
                  </p>
                  <p
                    className="text-[#1B262C] hover:opacity-80 duration-200 transition-opacity text-md font-bold cursor-pointer"
                    onClick={addVatLine}
                  >
                    {t('Add a VAT line')}
                  </p>
                </div>

                {vatLines?.map((vatLine, index) => (
                  <div key={index} className="flex items-end gap-2">
                    <Input
                      label={t('vatAmount')}
                      type="number"
                      value={vatLine?.vatAmount?.toString() || ''}
                      labelCls="text-[#7B7B8D] text-md"
                      onInput={(e) => handleChange('vatAmount', index, e)}
                    />

                    <Input
                      label={t('vatTaxAmount')}
                      type="number"
                      value={vatLine?.vatTaxAmount?.toString() || ''}
                      labelCls="text-[#7B7B8D] text-md"
                      onInput={(e) => handleChange('vatTaxAmount', index, e)}
                    />

                    <Input
                      label={t('vatTaxRate')}
                      type="number"
                      value={
                        vatLine?.vatTaxRate !== null
                          ? vatLine.vatTaxRate.toString()
                          : ''
                      }
                      labelCls="text-[#7B7B8D] text-md whitespace-nowrap"
                      onInput={(e) => handleChange('vatTaxRate', index, e)}
                    />

                    <div
                      className="bg-[#FFE0E0] rounded-full p-3 h-fit flex justify-center items-center hover:opacity-75 transition-opacity duration-200 cursor-pointer"
                      onClick={() => deleteVatLine(index)}
                    >
                      <Delete />
                    </div>
                  </div>
                ))}
              </div>

              <Button
                type="submit"
                label={t('Validate')}
                cls="w-full hover:opacity-80 transition-opacity duration-200"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ReceiptPage;

import moment from 'moment';
import ReactDOM from 'react-dom/client';

import App from './App';
import i18n from './i18n';
import './index.scss';
import Providers from './lib/Providers';

moment.locale(i18n.language);

const root = ReactDOM.createRoot(document.getElementById('root')!);

const Main = (
  <Providers>
    <App />
  </Providers>
);

const env = process.env.NODE_ENV;

if (env === 'development')
  import('../mocks/browser')
    .then(({ worker }) => worker.start())
    .then(() => root.render(Main))
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error during worker registration:', error)
    );
else root.render(Main);

import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { IconPosition } from './types';

export const getCls = (
  applyDefaultCls: boolean,
  defaultCls: string,
  cls: string
) => (applyDefaultCls ? twMerge(defaultCls, cls) : cls);

export const getIconSuitableCls = (
  cls: string,
  icon: ReactNode | undefined,
  iconPosition: IconPosition = 'left'
) => (icon ? twMerge(cls, iconPosition === 'left' ? 'pl-10' : 'pr-10') : cls);

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import {
  CheckCircleRounded,
  CloseRounded,
  MenuRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { whiteLogo, wizardSidebarPattern } from '@/assets/images';
import type { BankAccountType, CompanyInfosFormType } from '@/lib/forms';
import { cls } from '@/utils/funcs';

enum StateEnum {
  INITIAL,
  ACTIVE,
  DONE,
}

export interface WizardData {
  companyInfo: CompanyInfosFormType & {
    name: string;
  };
  bankAccounts: BankAccountType[];
  subscription: object;
}

interface WizardProps {}

const Wizard: FC<WizardProps> = () => {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [isSideBarOpen, setIsOpenSideBar] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();

  const [wizardData, setWizardData] = useState<WizardData>({
    // @ts-ignore
    companyInfo: {
      address: '',
      legalStatus: '',
      name: '',
      taxId: '',
      logo: '',
    },
    bankAccounts: [],
    subscription: {},
  });

  const steps = [
    {
      title: t('Company Information'),
      href: 'company-info',
    },
    {
      title: t('Bank Accounts'),
      href: 'bank-accounts',
    },
    {
      title: t('Subscription'),
      href: 'subscription',
    },
    { href: '' },
  ];

  useEffect(() => {
    const stepIdx = steps.findIndex(({ href }) =>
      location.pathname.endsWith(href)
    );

    setCurrentStepIdx(stepIdx);
  }, [location.pathname]);

  const getState = (idx = currentStepIdx) => {
    if (idx < currentStepIdx) return StateEnum.DONE;
    if (idx === currentStepIdx) return StateEnum.ACTIVE;
    return StateEnum.INITIAL;
  };

  return (
    <div className="flex flex-row min-h-screen w-full">
      <div className="fixed top-0 bg-blue lg:hidden h-12 w-full z-[1000]">
        <button
          className="px-4 py-3 float-right"
          onClick={() => setIsOpenSideBar(!isSideBarOpen)}
        >
          {!isSideBarOpen ? (
            <MenuRounded sx={{ color: 'white' }} />
          ) : (
            <CloseRounded sx={{ color: 'white' }} />
          )}
        </button>
      </div>

      <aside
        className={cls(
          'sidebar fixed lg:sticky z-[1000] w-0',
          !isSideBarOpen
            ? 'w-0 -translate-x-full'
            : 'w-[290px] -translate-x-0 transition-transform duration-150 ease-in',
          'lg:w-[23%] 2xl:w-[337px] bg-blue top-0 h-screen lg:translate-x-0 overflow-hidden flex-none'
        )}
      >
        <div>
          {/*Logo*/}
          <div className="sidebar-header flex items-center justify-center py-8">
            <img
              className="max-w-[128px] min-w-[80px] w-[8vw]"
              alt="logo"
              src={whiteLogo}
            />
          </div>

          {/*Menu*/}
          <div className="sidebar-content pt-10 px-8 2xl:px-10">
            <ul className="flex flex-col 2xl:gap-y-9 gap-y-6">
              {steps.map(({ title }, idx) => {
                const state = getState(idx);

                return (
                  <li key={idx}>
                    <div className="gap-4 flex flex-row items-center cursor-default">
                      {idx + 1 !== 4 &&
                        (state === StateEnum.INITIAL ||
                          state === StateEnum.ACTIVE) && (
                          <div
                            className={`bg-${
                              state === StateEnum.INITIAL ? 'blue' : 'white'
                            } border-white rounded-full w-10 h-10 flex items-center justify-center border shrink-0`}
                          >
                            <p
                              className={`text-${
                                state === StateEnum.INITIAL
                                  ? 'white'
                                  : 'blue-600'
                              } font-bold font-['Switzer'] text-lg`}
                            >
                              {idx + 1}
                            </p>
                          </div>
                        )}

                      {state === StateEnum.DONE && (
                        <div className="bg-white rounded-full w-10 h-10 flex items-center justify-center">
                          <CheckCircleRounded style={{ color: 'green' }} />
                        </div>
                      )}
                      <p className="xl:text-lg 2xl:text-xl lg:text-base font-medium text-white">
                        {title}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {/*Footer*/}
          <div className="sidebar-footer absolute bottom-0 w-48 2xl:w-64">
            <img alt="pattern" src={wizardSidebarPattern} />
          </div>
        </div>
      </aside>

      <main className="main lg:ml-0 mt-11 lg:mt-0 flex-col flex-grow  transition-all duration-150 ease-in">
        <div className="max-w-[100vw] pt-[33px] pb-6 h-full bg-first w-full">
          <Outlet context={{ wizardData, setWizardData }} />
        </div>
      </main>
    </div>
  );
};

export default Wizard;

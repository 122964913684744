export enum StatusEnum {
  ALL = 'ALL',
  NOT_ANALYZED = 'NOT_ANALYZED',
  ANALYZED = 'ANALYZED',
  ANALYSIS_SUCCESS = 'ANALYSIS_SUCCESS',
  NOT_ASSOCIATED = 'NOT_ASSOCIATED',
  ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
  ASSOCIATED = 'ASSOCIATED',
  VALIDATED = 'VALIDATED',
}

export enum ReceiptFilterStatus {
  INITIAL,
  IN_PROGRESS,
  DONE,
  ERROR,
}
export enum PaymentMethod {
  BANK_CARD = 'BANK_CARD',
}
export enum InvoiceType {
  SALE = 'SALE',
  PURCHASE = 'PURCHASE',
}

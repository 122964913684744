import type { ComponentProps, FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { IconPosition } from '..';
import { getCls, getIconSuitableCls } from '..';

const DEFAULT_CONTAINER_CLS = 'flex flex-col gap-2 w-full';
const DEFAULT_LABEL_CLS = 'block';
const DEFAULT_CLS =
  'h-12 px-4 py-2 rounded-xl border-2 border-solid border-[#D2D1D6] w-full';
const DEFAULT_ERROR_CLS =
  'h-12 px-4 py-2 rounded-xl border-2 border-solid border-red-400 bg-red-100 text-slate-900 placeholder-red-300 w-full';
const DEFAULT_ERROR_MSG_CLS = 'text-sm font-semibold text-red-400';
const DEFAULT_ICON_CONTAINER_CLS = 'absolute top-[11px]';

interface InputProps extends ComponentProps<'input'> {
  /**
   * @default ''
   */
  containerCls?: string;

  /**
   * @default ''
   */
  cls?: string;

  /**
   * @default ''
   */
  errorCls?: string;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default ''
   */
  errorMsgCls?: string;

  /**
   * @default ''
   */
  iconContainerCls?: string;

  /**
   * @default ''
   */
  label?: string /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  errorMsg?: string /* | DefaultTFuncReturn */;

  /**
   * @default false
   */
  hasError?: boolean;

  /**
   * @default true
   */
  applyDefaultContainerCls?: boolean;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorMsgCls?: boolean;

  /**
   * @default true
   */
  applyDefaultIconContainerCls?: boolean;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;

  /**
   * @default null
   */
  refs?: any;
}

/**
 * Global Input Component
 * @param {string} label label text
 * @param {string} errorMsg error message text
 * @param {string} containerCls container class
 * @param {string} cls input class
 * @param {string} errorCls input error class
 * @param {string} iconContainerCls input's icon container class
 * @param {string} labelCls label class
 * @param {boolean} hasError whether the input has an error
 * @param {boolean} applyDefaultContainerCls whether to apply the default container class
 * @param {boolean} applyDefaultCls whether to apply the default input class
 * @param {boolean} applyDefaultErrorCls whether to apply the default input error class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {boolean} applyDefaultErrorMsgCls whether to apply the default error message class
 * @param {boolean} applyDefaultIconContainerCls whether to apply the default input's icon container class
 * @param {ReactNode} icon icon component
 * @param {IconPosition} iconPosition icon position
 * @param {any[]} refs react-hook-form's register function references
 * @param {any[]} ...props list of props to pass to the native input element
 * @example <Input id="email" label="Email:" placeholder="Enter you email here.." icon={<EmailIcon />} errorMsg={errors.email && 'Please enter a valid email.'} refs={register('email')} />
 */
const Input: FC<InputProps> = ({
  containerCls = '',
  labelCls = '',
  cls = '',
  errorCls = '',
  errorMsgCls = '',
  iconContainerCls = '',
  label = '',
  errorMsg = '',
  hasError = false,
  applyDefaultContainerCls = true,
  applyDefaultCls = true,
  applyDefaultErrorCls = true,
  applyDefaultLabelCls = true,
  applyDefaultErrorMsgCls = true,
  applyDefaultIconContainerCls = true,
  icon = null,
  iconPosition = 'left',
  refs = null,
  ...props
}) => {
  hasError = hasError || !!errorMsg;

  containerCls = getCls(
    applyDefaultContainerCls,
    DEFAULT_CONTAINER_CLS,
    containerCls
  );

  labelCls = getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);

  cls = applyDefaultCls
    ? getIconSuitableCls(twMerge(DEFAULT_CLS, cls), icon, iconPosition)
    : cls;

  errorCls = applyDefaultErrorCls
    ? getIconSuitableCls(
        twMerge(DEFAULT_ERROR_CLS, errorCls),
        icon,
        iconPosition
      )
    : errorCls;

  errorMsgCls = getCls(
    applyDefaultErrorMsgCls,
    DEFAULT_ERROR_MSG_CLS,
    errorMsgCls
  );

  iconContainerCls = getCls(
    applyDefaultIconContainerCls,
    twMerge(
      DEFAULT_ICON_CONTAINER_CLS +
        (iconPosition === 'left' ? ' left-3' : ' right-3'),
      iconContainerCls
    ),
    iconContainerCls
  );

  return (
    <div className={containerCls}>
      {label && (
        <label htmlFor={props.id} className={labelCls}>
          {label}
        </label>
      )}

      <div className="relative">
        {icon && <div className={iconContainerCls}>{icon}</div>}

        <input className={hasError ? errorCls : cls} {...refs} {...props} />
      </div>

      {errorMsg && <p className={errorMsgCls}>{errorMsg}</p>}
    </div>
  );
};

export default Input;

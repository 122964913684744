import { subscriptionLock } from '@/assets/images';

export const LockedBackdrop = () => (
  <>
    <img
      src={subscriptionLock}
      alt="lock"
      className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] opacity-[1] z-[3]"
    />
    <div className="absolute h-[100%] w-[100%] bg-[#D9D9D9] left-[0] top-[0] z-[2] opacity-[.5]" />
  </>
);

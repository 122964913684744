import type {
  ComponentProps,
  FC,
  /* ReactNode */
} from 'react';

import { Controller } from 'react-hook-form';
import Select from 'react-select';

import { getCls } from '../helpers';

// import type { IconPosition } from '../types';

const DEFAULT_CONTAINER_CLS = 'flex flex-col gap-2';
const DEFAULT_LABEL_CLS = 'block';
const DEFAULT_ERROR_MSG_CLS = 'text-sm font-semibold text-red-400';

interface RSelectProps extends ComponentProps<typeof Select> {
  // placeholder?: string;
  // cls?: string;
  // errorCls?: string;
  // optionCls?: string;
  // iconContainerCls?: string;
  // icon?: ReactNode;
  // iconPosition?: IconPosition;
  // applyDefaultCls?: boolean;
  // applyDefaultErrorCls?: boolean;
  // applyDefaultOptionCls?: boolean;
  // applyDefaultIconContainerCls?: boolean;

  /**
   * @default ''
   */
  containerCls?: string;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default ''
   */
  errorMsgCls?: string;

  /**
   * @default ''
   */
  label?: string /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  errorMsg?: string /* | DefaultTFuncReturn */;

  // hasError?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorMsgCls?: boolean;

  /**
   * @default true
   */
  applyDefaultContainerCls?: boolean;

  /**
   * @default null
   */
  control?: any;

  /**
   * @default null
   */
  refs?: any;

  // children?: ReactNode;
}

/**
 * Global Select Component (based on `react-select`)
 * @param {string} label label text
 * @param {string} errorMsg error message text
 * @param {string} containerCls container class
 * @param {string} labelCls label class
 * @param {string} errorMsgCls error message class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {boolean} applyDefaultErrorMsgCls whether to apply the default error message class
 * @param {boolean} applyDefaultContainerCls whether to apply the default container class
 * @param {any[]} control react-hook-form's control object (this is mandatory for non-native HTML elements)
 * @param {any[]} refs react-hook-form's register function references
 * @param {any[]} ...props list of props to pass to react-select
 * @example <RSelect id="selectedNumber" label="Selected number:" placeholder="Select a number.." options={[{ label: 'One', value: 'one' }, { label: 'Two', value: 'two' }]} errorMsg={errors.selectedNumber && 'Please select a number.'} control={control} refs={register('selectedNumber')} />
 */
const RSelect: FC<RSelectProps> = ({
  label = '',
  errorMsg = '',
  containerCls = '',
  labelCls = '',
  errorMsgCls = '',
  // hasError,
  applyDefaultLabelCls = true,
  applyDefaultErrorMsgCls = true,
  applyDefaultContainerCls = true,
  control = null,
  refs = null,
  ...props
}) => {
  containerCls = getCls(
    applyDefaultContainerCls,
    DEFAULT_CONTAINER_CLS,
    containerCls
  );

  labelCls = getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);

  errorMsgCls = getCls(
    applyDefaultErrorMsgCls,
    DEFAULT_ERROR_MSG_CLS,
    errorMsgCls
  );

  return (
    <div className={containerCls}>
      {label && (
        <label htmlFor={props.id} className={labelCls}>
          {label}
        </label>
      )}

      <Controller
        name={refs.name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              isSearchable={false}
              {...props}
              {...{
                ...field,
                value:
                  props && props?.options
                    ? props.options.find(
                        (option: any) => option.value === field.value
                      )
                    : null,
                onChange: (val: any) => field.onChange(val.value),
              }}
            />
          );
        }}
      />

      {errorMsg && <p className={errorMsgCls}>{errorMsg}</p>}
    </div>
  );
};

export default RSelect;
